import getDeliveryAvailableDateAPI from './api/getAvailableDate'
import { IGetDeliveryAvailableDateRequest } from './interfaces/IGetAvailableDateRequest'
import {
  CalendarExceptions,
  FetchCalendarExceptionsParams
} from '@/store/modules/deliveryDates/interfaces/calendarException'
import { fetchExceptions } from '@/store/modules/deliveryDates/api/getCalendarExceptions'
import { filterCalendarExceptions, getShipmentForExceptions, sortByDateAsc } from '@/utils/deliveryDate'
import { IGetDeliveryAvailableDateResponse } from '@/store/modules/deliveryDates/interfaces/IGetAvailableDateResponse'
import { DeliveryDatesMutationTypes } from '@/store/modules/deliveryDates/interfaces/mutation-types'
import { DeliveryDatesActionTypes } from '@/store/modules/deliveryDates/interfaces/action-types'
import { IDeliveryDatesState } from '@/store/modules/deliveryDates/interfaces/state'
import { IDeliveryDatesMutations } from '@/store/modules/deliveryDates/interfaces/IDeliveryDatesMutation'
import { IDeliveryDatesActions } from '@/store/modules/deliveryDates/interfaces/IDeliveryDatesAction'
import { TransportationTypes } from '@/utils/transportTypes'

const state = (): IDeliveryDatesState => ({
  availableDays: [],
  filteredDeliveryDates: [],
  calendarException: [],
  unavailableDates: [],
  scheduleDates: []
})

const getters = {}

const mutations: IDeliveryDatesMutations = {
  [DeliveryDatesMutationTypes.SET_AVAILABLE_DAYS] (state, payload: string[]) {
    state.availableDays = payload
  },
  [DeliveryDatesMutationTypes.SET_FILTERED_AVAILABLE_DAYS] (state, payload: string[]) {
    state.filteredDeliveryDates = payload
  },
  [DeliveryDatesMutationTypes.SET_CALENDAR_EXCEPTIONS] (state, payload: CalendarExceptions[]) {
    state.calendarException = payload
  },
  [DeliveryDatesMutationTypes.SET_UNAVAILABLE_DATES] (state, payload: string[]) {
    state.unavailableDates = payload
  },
  [DeliveryDatesMutationTypes.SET_SCHEDULE_DATES] (state, payload: string[]) {
    state.scheduleDates = payload
  }
}

const actions: IDeliveryDatesActions = {
  [DeliveryDatesActionTypes.GET_DELIVERY_AVAILABLE_DATE]: async ({ commit }, dto: IGetDeliveryAvailableDateRequest) => {
    const res: IGetDeliveryAvailableDateResponse = await getDeliveryAvailableDateAPI(dto)

    let allDates = res?.allDeliveryDate || []
    let filteredDates = res?.filteredDeliveryDate || []

    const deliveryExceptions = res?.deliveryExceptions || []

    let unavailableDates: string[] = []
    let scheduleDates: string[] = []

    deliveryExceptions.forEach(exception => {
      const mappedTransportation = getShipmentForExceptions(dto.shipmentId as TransportationTypes)

      if (!((mappedTransportation === TransportationTypes.SMALL && (exception.standardorder === true || exception.standardorder === 'True')) ||
            (mappedTransportation === TransportationTypes.TRUCK && (exception.truckorder === true || exception.truckorder === 'True')))) {
        return
      }
      const originalDate = formatAsIsoUtcMidnight(exception.originalDate)
      const scheduledDate = formatAsIsoUtcMidnight(exception.scheduledDate)

      if (!isNaN(Date.parse(originalDate))) {
        unavailableDates.push(originalDate)

        allDates = allDates.filter(date => date !== originalDate)
        filteredDates = filteredDates.filter(date => date !== originalDate)
      }

      if (!isNaN(Date.parse(scheduledDate))) {
        scheduleDates.push(scheduledDate)
        if (!allDates.includes(scheduledDate)) {
          allDates.push(scheduledDate)
        }
        if (!filteredDates.includes(scheduledDate)) {
          filteredDates.push(scheduledDate)
        }
      }
    })

    allDates = sortByDateAsc(allDates)
    filteredDates = sortByDateAsc(filteredDates)
    scheduleDates = sortByDateAsc(scheduleDates)
    unavailableDates = sortByDateAsc(unavailableDates)

    commit(DeliveryDatesMutationTypes.SET_AVAILABLE_DAYS, allDates)
    commit(DeliveryDatesMutationTypes.SET_FILTERED_AVAILABLE_DAYS, filteredDates)
    commit(DeliveryDatesMutationTypes.SET_UNAVAILABLE_DATES, unavailableDates)
    commit(DeliveryDatesMutationTypes.SET_SCHEDULE_DATES, scheduleDates)
  },

  async [DeliveryDatesActionTypes.FETCH_CALENDAR_EXCEPTION] ({ commit }, params: FetchCalendarExceptionsParams) {
    const data = await fetchExceptions(params.guid, params.shipTo, params.store)
    if (data) {
      const filteredData = filterCalendarExceptions(data)
      commit(DeliveryDatesMutationTypes.SET_CALENDAR_EXCEPTIONS, filteredData)
    }
  },
  async [DeliveryDatesActionTypes.SET_FETCHED_CALENDAR_EXCEPTION] ({ commit }, data: CalendarExceptions[]) {
    commit(DeliveryDatesMutationTypes.SET_CALENDAR_EXCEPTIONS, data)
  }
}

export function formatAsIsoUtcMidnight (dateStr: string): string {
  const d = new Date(dateStr)
  const year = d.getFullYear()
  const month = String(d.getMonth() + 1).padStart(2, '0')
  const day = String(d.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}T00:00:00Z`
}

const DeliveryDatesModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default DeliveryDatesModule
