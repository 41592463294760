import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bd18152"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calendar-modal" }
const _hoisted_2 = { class: "calendar-modal-header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "calendar-modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calendar_icon = _resolveComponent("calendar-icon")!
  const _component_package_icon = _resolveComponent("package-icon")!
  const _component_AsahiTypography = _resolveComponent("AsahiTypography")!
  const _component_calendar_home_notification_change_line = _resolveComponent("calendar-home-notification-change-line")!
  const _component_AsahiButton = _resolveComponent("AsahiButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.isMobile? 'calendar-modal-header-icon-mobile': 'calendar-modal-header-icon')
      }, [
        (!_ctx.isMobile || !_ctx.showChanges)
          ? (_openBlock(), _createBlock(_component_calendar_icon, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.isMobile && _ctx.showChanges)
          ? (_openBlock(), _createBlock(_component_package_icon, { key: 1 }))
          : _createCommentVNode("", true)
      ], 2),
      (!_ctx.showChanges && _ctx.isMobile)
        ? (_openBlock(), _createBlock(_component_AsahiTypography, {
            key: 0,
            type: _ctx.isMobile ? 'subtitle-2-extrabold': 'body-2-bold'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Notification of change in future delivery')), 1)
            ]),
            _: 1
          }, 8, ["type"]))
        : _createCommentVNode("", true),
      (_ctx.showChanges && _ctx.isMobile)
        ? (_openBlock(), _createBlock(_component_AsahiTypography, {
            key: 1,
            type: _ctx.isMobile ? 'subtitle-2-extrabold': 'body-2-bold'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Delivery changes')), 1)
            ]),
            _: 1
          }, 8, ["type"]))
        : _createCommentVNode("", true)
    ]),
    (!(_ctx.showChanges && _ctx.isMobile))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('We would like to inform you that there will be a change in your future delivery. Please view the details of the change.')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showChanges)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exceptions, (exception, i) => {
            return (_openBlock(), _createBlock(_component_calendar_home_notification_change_line, {
              key: i,
              exception: exception,
              "is-mobile": _ctx.isMobile,
              expand: _ctx.openedTabs[i],
              "onUpdate:expand": ($event: any) => ((_ctx.openedTabs[i]) = $event)
            }, null, 8, ["exception", "is-mobile", "expand", "onUpdate:expand"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (!_ctx.isSomethingOpened)
        ? (_openBlock(), _createBlock(_component_AsahiButton, {
            key: 0,
            type: "primary",
            class: _normalizeClass({'w-100': _ctx.isMobile}),
            onClick: _ctx.clickContinue
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t( 'Preview' )), 1)
            ]),
            _: 1
          }, 8, ["class", "onClick"]))
        : (_openBlock(), _createBlock(_component_AsahiButton, {
            key: 1,
            type: "primary",
            class: _normalizeClass({'w-100': _ctx.isMobile}),
            onClick: _ctx.clickCollapse
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t( 'Collapse all changes')), 1)
            ]),
            _: 1
          }, 8, ["class", "onClick"]))
    ])
  ]))
}