
import { defineComponent } from 'vue'
import DeliveryDatePickerInner from './DeliveryDatePickerInner.vue'

export default defineComponent({
  name: 'DeliveryDatePicker',

  props: {
    modelValue: {
      type: String,
      required: true
    },
    isCompact: {
      type: Boolean,
      default: false
    },
    shipmentId: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    hasCalendarException: {
      type: Boolean,
      default: false
    },
    rewrittenShipToId: {
      type: String,
      default: null
    }
  },

  emits: ['update:modelValue', 'open', 'close'],

  components: {
    DeliveryDatePickerInner
  },

  data: () => ({
    componentKey: 0,
    isDatePickerOpened: false
  }),

  methods: {
    forceRecalculate () {
      this.componentKey += 1
    },

    onDatePickerOpened (opened: boolean) {
      setTimeout(() => {
        this.isDatePickerOpened = opened

        // Emit event based on whether the date picker is opened or closed
        if (opened) {
          this.$emit('open') // Emit 'open' event when the date picker opens
        } else {
          this.$emit('close') // Emit 'close' event when the date picker closes
        }
      }, 100)
    }
  }
})
