import { PromoPriceLeaflet } from '@/store/modules/catalog/interfaces/products/IProduct'
import _ from 'lodash'

export function updatePromoValidity (leaflet: PromoPriceLeaflet | undefined, yourPrice: number, yourPriceVat: number): { promo_price_leaflet: PromoPriceLeaflet | undefined, your_price: number, your_price_vat: number } {
  if (!leaflet) {
    return { promo_price_leaflet: leaflet, your_price: yourPrice, your_price_vat: yourPriceVat }
  }

  const updatedLeaflet = _.cloneDeep(makePromoPricesNumbers(leaflet))
  const vatValue = (100 + updatedLeaflet.percentage) / 100
  if (updatedLeaflet) {
    updatedLeaflet.base_price_vat = updatedLeaflet.base_price * vatValue
  }

  if (!(updatedLeaflet.promo_price_is_inside_delivery === 'Yes' || updatedLeaflet.promo_price_is_inside_delivery === true)) {
    updatedLeaflet.promo_price_valid_from = null
    updatedLeaflet.promo_price_valid_to = null
    updatedLeaflet.promo_price = 0
    updatedLeaflet.promo_price_percentage_wo_tax = 0
    updatedLeaflet.promo_price_percentage_with_tax = 0
  }
  return { promo_price_leaflet: updatedLeaflet, your_price: yourPrice, your_price_vat: yourPriceVat }
}

function makePromoPricesNumbers (leaflet: PromoPriceLeaflet): PromoPriceLeaflet {
  return {
    ...leaflet,
    promo_price: +leaflet.promo_price,
    promo_price_percentage_wo_tax: +leaflet.promo_price_percentage_wo_tax,
    promo_price_percentage_with_tax: +leaflet.promo_price_percentage_with_tax,
    long_term_discount: +leaflet.long_term_discount,
    long_term_discount_percentage_with_tax: +leaflet.long_term_discount_percentage_with_tax,
    long_term_discount_percentage_wo_tax: +leaflet.long_term_discount_percentage_wo_tax,
    base_price: +leaflet.base_price,
    base_price_vat: +(leaflet?.base_price_vat || 0),
    percentage: +leaflet.percentage
  }
}
