
import { defineComponent } from 'vue'
import formatDateMixin from '@/utils/mixin'
import AsahiTypography from '@/components/library/Typography.vue'

export default defineComponent({
  name: 'CalendarHomeNotificationChange',
  components: { AsahiTypography },
  mixins: [formatDateMixin],
  props: {
    changes: {
      type: Object,
      required: true
    }
  }
})
