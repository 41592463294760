
import { defineComponent, PropType } from 'vue'
import CalendarHomeNotificationChangeMobile from '@/components/calendar/CalendarHomeNotificationChangeMobile.vue'
import AccordionArrowIcon from '@/components/library/icons/AccordionArrowIcon.vue'
import AsahiTypography from '@/components/library/Typography.vue'
import CalendarHomeNotificationChange from '@/components/calendar/CalendarHomeNotificationChange.vue'
import { CalendarExceptions } from '@/store/modules/deliveryDates/interfaces/calendarException'

export default defineComponent({
  name: 'CalendarHomeNotificationChangeLine',
  components: { CalendarHomeNotificationChange, AsahiTypography, AccordionArrowIcon, CalendarHomeNotificationChangeMobile },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    expand: {
      type: Boolean,
      default: false
    },
    exception: {
      type: Object as PropType<CalendarExceptions>,
      required: true
    }
  },
  emits: ['update:expand'],
  computed: {
    deliveryExceptions (): any[] {
      return this.exception.deliveryExceptions.flatMap((exception) => {
        return exception.deliveryExceptions.map((deliveryException) => {
          return {
            ...deliveryException,
            transportation: exception.transportation
          }
        })
      })
    },
    expanded: {
      get () {
        return this.expand
      },
      set (value: boolean) {
        this.$emit('update:expand', value)
      }
    },
    shipToDescription (): string {
      const { permissionSchema = [] } = this.$store.state.auth.user || {}
      return permissionSchema
        .filter((permission: any) =>
          permission.rpDetails.some((role: any) => role.permission.includes('Catalogue') && permission.shipToStatus === 'APPROVED')
        )
        .map((permission: any) => ({
          label: permission.shipToName,
          shipToId: permission.shipToId,
          description: permission?.shipToAddress
        }))
        .filter((option) => option.shipToId === this.exception.shipToId)[0].description
    }
  }
})
