
import { defineComponent, PropType } from 'vue'
import CalendarIcon from '@/components/library/icons/CalendarIcon.vue'
import AsahiTypography from '@/components/library/Typography.vue'
import AsahiButton from '@/components/library/AsahiButton.vue'
import CalendarHomeNotificationChangeLine from '@/components/calendar/CalendarHomeNotificationChangeLine.vue'
import PackageIcon from '@/components/library/icons/PackageIcon.vue'
import { CalendarExceptions } from '@/store/modules/deliveryDates/interfaces/calendarException'

export default defineComponent({
  name: 'CalendarHomeNotificationModal',
  components: { PackageIcon, CalendarHomeNotificationChangeLine, AsahiButton, AsahiTypography, CalendarIcon },
  emits: ['details'],
  data () {
    return {
      openChanges: false,
      numberOfOpened: 0,
      openedTabs: [] as boolean[]
    }
  },
  props: {
    showChanges: {
      type: Number,
      default: 1
    },
    exceptions: {
      type: Object as PropType<CalendarExceptions[]>,
      required: true
    },
    closeModal: {
      type: Function,
      default: () => {
        /* */
      }
    }
  },
  mounted () {
    this.openedTabs = this.exceptions.map(() => false)
  },
  computed: {
    isMobile () {
      return window.innerWidth <= 768
    },
    isSomethingOpened () {
      return this.openedTabs.includes(true)
    }
  },
  methods: {
    clickContinue () {
      if (!this.showChanges) {
        this.$emit('details')
        this.closeModal()
      } else {
        this.openedTabs = this.openedTabs.map(() => true)
      }
    },
    clickCollapse () {
      this.openedTabs = this.openedTabs.map(() => false)
    }
  }
})

