
import { defineComponent, ref, Ref } from 'vue'
import { getDeliveryDaysOnWeek, getHolidayDays, getUTCDate } from '@/utils/deliveryDate'
import { format, getDay } from 'date-fns'

import { mapState } from 'vuex'
import type { DatePickerInstance } from '@vuepic/vue-datepicker'
/**
 * use this to get documentation of vue-datepicker
 * https://vue3datepicker.com/props/calendar-configuration/
 */
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import AsahiTypography from '@/components/library/Typography.vue'

import ChevronLeftIcon from '@/assets/icons/library/chevron-left.svg'
import ChevronRightIcon from '@/assets/icons/library/chevron-right.svg'
import CalendarIcon from '@/assets/icons/calendar-white.svg'
import AsahiButton from '../library/AsahiButton.vue'
import ExclamationIcon from '@/components/library/icons/ExclamationIcon.vue'
import AngleUpDownIcon from '@/components/library/icons/AngleUpDownIcon.vue'
import AngleLeftRightIcon from '@/components/library/icons/AngleLeftRightIcon.vue'
import { mapTransportType, TransportationTypes } from '@/utils/transportTypes'
import { CalendarExceptions } from '@/store/modules/deliveryDates/interfaces/calendarException'
import formatDateMixin from '@/utils/mixin'

export default defineComponent({
  name: 'DeliveryDatePickerInner',
  components: {
    AngleLeftRightIcon,
    AngleUpDownIcon,
    ExclamationIcon,
    AsahiTypography,
    VueDatePicker,
    AsahiButton
  },
  mixins: [formatDateMixin],
  props: {
    modelValue: {
      type: String,
      required: true
    },
    isCompact: {
      type: Boolean,
      default: false
    },
    shipmentId: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isPlaceholder: {
      type: Boolean,
      default: false
    },
    hasCalendarException: {
      type: Boolean,
      default: false
    },
    rewrittenShipToId: {
      type: String,
      default: null
    }
  },

  emits: ['update:modelValue', 'datePickerOpen'],

  setup () {
    const vueDatePickerRef: Ref<DatePickerInstance> = ref(null)

    return { vueDatePickerRef }
  },
  data: () => ({
    deliveryDate: '' as string,
    firstAvailableDate: '' as string,
    changedShipmentId: false,
    ChevronLeftIcon,
    ChevronRightIcon,
    CalendarIcon,
    deliveryDateToSend: '' as string,
    markers: ref([
      {
        date: new Date(),
        type: 'dot',
        color: '#1A3E13'
      }
    ]),
    inputClassName: 'input-delivery-date',
    store: '',
    isLoading: false,
    isOpen: false,
    shipToId: ''
  }),

  computed: {
    ...mapState('main', ['holidaysConfig']),
    ...mapState('catalog', ['shipTos']),
    ...mapState('deliveryDates', ['availableDays', 'unavailableDates', 'scheduleDates', 'calendarException']),

    user () {
      return this.$store.state.auth.user
    },

    availableDates (): string[] {
      return this.getAvailableDates()
    },

    localeValue () {
      return this.$i18n.locale as string
    },
    holidays () {
      return getHolidayDays(this.holidaysConfig)
    },
    dayOfWeek () {
      return [this.$t('MON'), this.$t('TUE'), this.$t('WED'), this.$t('THU'), this.$t('FRI'), this.$t('SAT'), this.$t('SUN')]
    },

    exceptions () {
      let transportation = mapTransportType(this.shipmentId)
      if (transportation === mapTransportType(TransportationTypes.EMPTY_TRUCK)) {
        transportation = mapTransportType(TransportationTypes.TRUCK)
      } else if (transportation === mapTransportType(TransportationTypes.EMPTY_SMALL) ||
      transportation === mapTransportType(TransportationTypes.SMALL_TRUCK)) {
        transportation = mapTransportType(TransportationTypes.SMALL)
      }
      if (this.shipToId && transportation) {
        const exception = this.calendarException?.find((exception: CalendarExceptions) => exception.shipToId === this.shipToId) || null
        if (exception) {
          return exception.deliveryExceptions?.find((deliveryException: any) => deliveryException.transportation === transportation)?.deliveryExceptions || null
        }
      }
      return null
    },

    getUnavailableDates () {
      if (this.unavailableDates?.length > 0) {
        return Array.from(
          this.unavailableDates.map((d: string) => {
            const dat = new Date(d)

            return format(dat, 'yyyy-MM-dd')
          }),
          (unavailableDate: string) => {
            return getUTCDate(new Date(unavailableDate))
          })
      }
      return []
    },

    getScheduleDates () {
      if (this.scheduleDates?.length > 0) {
        return Array.from(
          this.scheduleDates.map((d: string) => {
            const dat = new Date(d)

            return format(dat, 'yyyy-MM-dd')
          }),
          (scheduleDate: string) => {
            return getUTCDate(new Date(scheduleDate))
          })
      }
      return []
    }
  },

  watch: {
    modelValue: {
      handler (value) {
        if (value != null && value !== '') {
          this.deliveryDate = value
        }
      },
      immediate: true
    },

    shipmentId: {
      async handler (value, oldValue) {
        this.changedShipmentId = oldValue ? value !== oldValue : false

        if (this.changedShipmentId && this.shipToId) {
          await this.$store.dispatch('deliveryDates/GET_DELIVERY_AVAILABLE_DATE', { shipmentId: this.shipmentId, shipTo: this.shipToId, store: this.store })
        }
      }
    },

    isLoading () {
      this.updateInputWrapClass()
    },

    async rewrittenShipToId (newVal) {
      this.shipToId = newVal || (localStorage.getItem('shipToId') ?? '')
      if (this.shipToId) {
        await this.$store.dispatch('deliveryDates/GET_DELIVERY_AVAILABLE_DATE', { shipmentId: this.shipmentId, shipTo: this.shipToId, store: this.store })
      }
    }
  },

  async mounted () {
    this.isLoading = true
    this.store = localStorage.getItem('store') ?? ''
    if (this.isCompact) {
      this.inputClassName = 'input-delivery-date-black'
    }
    this.shipToId = this.rewrittenShipToId || (localStorage.getItem('shipToId') ?? '')
    if ((this.availableDays == null || this.availableDays?.length === 0) && this.shipToId) {
      await this.$store.dispatch('deliveryDates/GET_DELIVERY_AVAILABLE_DATE', { shipmentId: this.shipmentId, shipTo: this.shipToId, store: this.store })
    }
    this.isLoading = false
  },

  methods: {
    updateInputWrapClass () {
      const inputWrap = this.vueDatePickerRef?.$el.querySelector('.dp__input_wrap')
      if (inputWrap) {
        if (this.isLoading) {
          inputWrap.classList.add('full-width')
        } else {
          setTimeout(() => {
            inputWrap.classList.remove('full-width')
          }, 2000)
        }
      }
    },
    getAvailableDates () {
      if (this.availableDays?.length > 0) {
        this.firstAvailableDate = this.availableDays[0]
        return Array.from(
          this.availableDays.map((d: string) => {
            const dat = new Date(d)

            return format(dat, 'yyyy-MM-dd')
          }),
          (availableDate: string) => {
            return getUTCDate(new Date(availableDate))
          })
      }
      return []
    },

    setFirstAvailableDate (firstAvailableDate: string) {
      const firstAvailabledDate = new Date(firstAvailableDate)
      this.onDateSelection(getUTCDate(firstAvailabledDate), true)
    },

    onDateSelection (value: string, isFirstAvailable = false) {
      const deliveryDate = new Date(value)
      this.deliveryDateToSend = getUTCDate(deliveryDate)

      if (!this.isMobile) {
        this.$emit('update:modelValue', { value: this.deliveryDateToSend, isFirstAvailable })
      }
    },

    saveChanges () {
      this.vueDatePickerRef?.selectDate()

      if (this.deliveryDateToSend !== '') {
        this.$emit('update:modelValue', this.deliveryDateToSend)
      }
      this.vueDatePickerRef?.closeMenu()

      this.onCalendarClose()
    },

    getDayClass (date: Date) {
      const weekDay = getDay(date)
      const isWeekend = (weekDay % 6) === 0
      const dateStr = format(date, 'yyyy-MM-dd')
      const deliveryDateFormatted = format(new Date(this.deliveryDate), 'yyyy-MM-dd')
      const deliveryDaysOnWeek = getDeliveryDaysOnWeek(this.shipmentId, this.shipTos)
      const firstDateFormatted = format(new Date(this.firstAvailableDate), 'yyyy-MM-dd')
      if (format(new Date(), 'yyyy-MM-dd') > dateStr) {
        return ''
      }
      if (this.holidays.includes(dateStr) || this.getUnavailableDates.includes(dateStr)) {
        return 'holiday-cell'
      }
      if (deliveryDateFormatted === dateStr) {
        return 'active-cell'
      }
      if (this.getScheduleDates.includes(dateStr) || (this.availableDates.includes(dateStr) && deliveryDaysOnWeek.includes(weekDay))) {
        return 'delivery-cell'
      }
      if (dateStr >= firstDateFormatted && !isWeekend) {
        return 'working-cell'
      }
      return ''
    },
    onCalendarOpen () {
      this.shipToId = this.rewrittenShipToId || (localStorage.getItem('shipToId') ?? '')
      const component = this.$refs.vueDatePickerRef as any
      const el = component.$el as HTMLElement
      el.querySelectorAll('.dp__input_wrap').forEach((el) => {
        el.classList.add('open')
      })
      this.$emit('datePickerOpen', true)
      this.isOpen = true
    },
    onCalendarClose () {
      const component = this.$refs.vueDatePickerRef as any
      const el = component.$el as HTMLElement
      el.querySelectorAll('.dp__input_wrap').forEach((el) => {
        el.classList.remove('open')
      })
      this.$emit('datePickerOpen', false)
      this.isOpen = false
    },
    toggleDatePicker () {
      this.$nextTick(() => {
        if (this.vueDatePickerRef) {
          if (!this.isOpen) {
            this.vueDatePickerRef.openMenu()
          } else {
            this.vueDatePickerRef.closeMenu()
          }
        }
      })
    }
  }
})
