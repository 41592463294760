
import { defineComponent } from 'vue'
import { HelpPagesActionType } from '@/store/modules/help-pages/interfaces'
import { AuthActionTypes } from '@/store/modules/auth/interfaces/action-types'
import { AuthMutationTypes } from '@/store/modules/auth/interfaces/mutation-types'
import AsahiTypography from '@/components/library/Typography.vue'
import AsahiButton from './library/AsahiButton.vue'
import refreshAfterChangeLanguage from '@/mixins/refreshAfterChangeLanguage'
import DropDown from './DropDown.vue'

import 'vue3-carousel/dist/carousel.css'
import AsahiClose from './library/assets/AsahiClose.vue'
import { PageContentsActionType } from '@/store/modules/page-contents/interfaces'
import { addSeenHelpPages, getUnseenHelpPages } from '@/utils/newCapabilities'
import { cdcServiceAPI } from '@/store/api-instance'

export default defineComponent({
  name: 'TutorialModal',

  components: {
    AsahiTypography,
    AsahiButton,
    AsahiClose,
    DropDown
  },

  mixins: [refreshAfterChangeLanguage],

  data () {
    return {
      currentSlide: 0
    }
  },

  props: {
    closeModal: {
      type: Function,
      default: () => {
        /* */
      }
    }
  },

  created () {
    this.refresh()
  },

  computed: {

    isOnHomepage () {
      return this.$route.path === '/'
    },

    user () {
      return this.$store.state.auth.user
    },

    helpPagesStore () {
      return this.$store.state.helpPages
    },

    pageContentStore () {
      return this.$store.state.pageContents.pageContents.find(p => p.locationId === 'tutorialHeading')
    },

    getCurrenthelpPage () {
      return this.sortedHelpPages[this.currentSlide]
    },

    sortedHelpPages () {
      let helpPages = this.helpPagesStore.helpPages
      if ((this.user.firstLogin === false) && !(this.user.showTutorial > 0) && !(this.user.showTutorial === null)) {
        helpPages = getUnseenHelpPages(this.helpPagesStore.helpPages.filter(h => h?.newCapability === true), this.user.UID)
      }
      return [...helpPages].sort((a, b) => (a.page || 0) - (b.page || 0))
    }
  },

  methods: {

    refresh () {
      this.$store.dispatch(HelpPagesActionType.FETCH_ALL_HELP_PAGES)
      this.$store.dispatch(PageContentsActionType.FETCH_PAGE_CONTENTS, {
        locationId: true
      })
    },

    async goToNextSlide () {
      if (this.currentSlide >= (this.sortedHelpPages.length - 1)) {
        return await this.done()
      }
      this.currentSlide++
    },

    goToPreviousSlide () {
      if (this.currentSlide > 0) {
        this.currentSlide--
      }
    },

    selectSection (idx: number) {
      this.currentSlide = idx
    },

    handleInit () {
      this.currentSlide = 0
    },

    handleSlideStart ({ slidingToIndex }: any) {
      this.currentSlide = slidingToIndex
    },

    async done () {
      const userInfo = this.$store.state.auth.user
      if ((userInfo.firstLogin === true) || (userInfo.showTutorial === null)) {
        userInfo.firstLogin = false
        this.$store.commit(AuthMutationTypes.SET_USER_INFO, userInfo)
        this.$store.dispatch(AuthActionTypes.SET_FIRST_LOGIN, {
          UID: userInfo.UID,
          firstLogin: false
        })
      }
      await cdcServiceAPI.put('users/SetUserTutorialCounter/' + this.user.UID)
      addSeenHelpPages(this.sortedHelpPages, this.user.UID)
      this.closeModal()
    },

    back () {
      (this.$refs.tutorialCarousel as any).prev()
    },

    next () {
      (this.$refs.tutorialCarousel as any).next()
    }
  }
})
