import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "exclamation-icon"
}
const _hoisted_2 = { class: "datepicker-mobile-body" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "custom-day-week" }
const _hoisted_5 = { class: "icons" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "custom-month-year-component" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "d-flex justify-content-start" }
const _hoisted_12 = { class: "delivery-legends" }
const _hoisted_13 = { class: "non-delivery-legends" }
const _hoisted_14 = {
  key: 0,
  class: "row p-2"
}
const _hoisted_15 = { class: "col-12" }
const _hoisted_16 = {
  key: 1,
  class: "table-container"
}
const _hoisted_17 = { class: "grid-row grid-row-header" }
const _hoisted_18 = { class: "grid-cell change-old-value" }
const _hoisted_19 = { class: "change-date" }
const _hoisted_20 = {
  key: 0,
  class: "change-time"
}
const _hoisted_21 = { class: "grid-cell change-new-value" }
const _hoisted_22 = { class: "change-date" }
const _hoisted_23 = {
  key: 0,
  class: "change-time"
}
const _hoisted_24 = { class: "grid-cell change-order-by change-date" }
const _hoisted_25 = {
  key: 0,
  class: "exclamation-icon"
}
const _hoisted_26 = {
  key: 1,
  class: "angle-icon"
}
const _hoisted_27 = {
  key: 2,
  class: "angle-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationIcon = _resolveComponent("ExclamationIcon")!
  const _component_AsahiTypography = _resolveComponent("AsahiTypography")!
  const _component_AsahiButton = _resolveComponent("AsahiButton")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_AngleUpDownIcon = _resolveComponent("AngleUpDownIcon")!
  const _component_AngleLeftRightIcon = _resolveComponent("AngleLeftRightIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["datepicker-wrapper", {'datepicker-wrapper-mobile': _ctx.isMobile}]),
    onPointerdown: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ( _ctx.isMobile? _ctx.toggleDatePicker(): () => {} ), ["stop","prevent"]))
  }, [
    (_ctx.hasCalendarException && _ctx.isMobile)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createVNode(_component_ExclamationIcon)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VueDatePicker, {
        modelValue: _ctx.deliveryDate,
        "onUpdate:modelValue": [
          _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deliveryDate) = $event)),
          _ctx.onDateSelection
        ],
        ref: "vueDatePickerRef",
        "auto-apply": !_ctx.isMobile,
        "action-row": { showNow: false, showPreview: false },
        "enable-time-picker": false,
        "month-change-on-scroll": false,
        config: {keepActionRow: false, onClickOutside: false},
        "hide-offset-dates": true,
        "allowed-dates": _ctx.availableDates,
        locale: _ctx.localeValue,
        clearable: false,
        "min-date": _ctx.firstAvailableDate,
        "month-name-format": "long",
        format: "dd.MM.yyyy",
        "calendar-class-name": "custom-calendar",
        "menu-class-name": "custom-menu",
        "input-class-name": _ctx.inputClassName,
        "day-class": _ctx.getDayClass,
        markers: _ctx.markers,
        onFocus: _ctx.onCalendarOpen,
        onOpen: _ctx.onCalendarOpen,
        onClosed: _ctx.onCalendarClose,
        onBlur: _ctx.onCalendarClose,
        "teleport-center": _ctx.isMobile,
        class: _normalizeClass([_ctx.isCompact ? 'invert-filter' : '']),
        "auto-position": false
      }, {
        "input-icon": _withCtx(() => [
          (!_ctx.isCompact)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.CalendarIcon,
                alt: "Calendar icon"
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ]),
        "calendar-header": _withCtx(({ index }) => [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.dayOfWeek[index]), 1)
        ]),
        "month-year": _withCtx(({
            month,
            year,
            months,
            handleMonthYearChange
        }) => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", {
              class: "custom-icon",
              onClick: ($event: any) => (handleMonthYearChange(false))
            }, [
              _createElementVNode("img", {
                src: _ctx.ChevronLeftIcon,
                alt: "Chevron left icon"
              }, null, 8, _hoisted_7)
            ], 8, _hoisted_6),
            _createElementVNode("div", _hoisted_8, _toDisplayString(months !== undefined && month !== undefined ? months[month].text : '') + " " + _toDisplayString(year), 1),
            _createElementVNode("span", {
              class: "custom-icon",
              onClick: ($event: any) => (handleMonthYearChange(true))
            }, [
              _createElementVNode("img", {
                src: _ctx.ChevronRightIcon,
                alt: "Chevron right icon"
              }, null, 8, _hoisted_10)
            ], 8, _hoisted_9)
          ])
        ]),
        "action-extra": _withCtx(() => [
          _createElementVNode("ul", _hoisted_11, [
            _createElementVNode("li", _hoisted_12, [
              _createVNode(_component_AsahiTypography, {
                type: "subtitle-1",
                color: "primary-black"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Delivery days')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_13, [
              _createVNode(_component_AsahiTypography, {
                type: "subtitle-1",
                color: "primary-black"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Non-delivery days')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          (_ctx.isMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_AsahiButton, {
                    type: "primary",
                    class: "w-100",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveChanges()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Select")), 1)
                    ]),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.exceptions && _ctx.exceptions.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_AsahiTypography, {
                    class: "grid-cell",
                    type: "subtitle-1-bold"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Original delivery')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_AsahiTypography, {
                    class: "grid-cell",
                    type: "subtitle-1-bold"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Next delivery')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_AsahiTypography, {
                    class: "grid-cell",
                    type: "subtitle-1-bold"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Order by')), 1)
                    ]),
                    _: 1
                  })
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exceptions, (exception, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: i,
                    class: "grid-row"
                  }, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.formatDate(exception.originalDate)), 1),
                      (exception.originalHours)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(exception.originalHours), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.formatDate(exception.scheduledDate)), 1),
                      (exception.scheduledHour)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(exception.scheduledHour), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.formatDate(exception.orderBy)), 1)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        "action-buttons": _withCtx(() => []),
        _: 1
      }, 8, ["modelValue", "auto-apply", "allowed-dates", "locale", "min-date", "input-class-name", "day-class", "markers", "onFocus", "onOpen", "onClosed", "onBlur", "onUpdate:modelValue", "teleport-center", "class"]),
      _createElementVNode("span", {
        class: "datepicker-wrapper",
        onPointerdown: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.isMobile? () => {} : _ctx.toggleDatePicker()), ["stop","prevent"]))
      }, [
        (_ctx.hasCalendarException && !_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("span", _hoisted_25, [
              _createVNode(_component_ExclamationIcon)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("span", _hoisted_26, [
              _createVNode(_component_AngleUpDownIcon, { up: _ctx.isOpen }, null, 8, ["up"])
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_27, [
              _createVNode(_component_AngleLeftRightIcon, { right: true })
            ]))
      ], 32)
    ])
  ], 34))
}