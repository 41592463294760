
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AccordionArrowIcon',
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  }
})
