
import { defineComponent } from 'vue'
import AsahiTypography from '@/components/library/Typography.vue'
import formatDateMixin from '@/utils/mixin'

export default defineComponent({
  name: 'CalendarHomeNotificationChangeMobile',
  components: { AsahiTypography },
  mixins: [formatDateMixin],
  props: {
    changes: {
      type: Object,
      required: true
    }
  }
})
