
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import ResizeObserver from '@/components/ResizeObserver.vue'
import TutorialModal from '@/components/TutorialModal.vue'
import MessageModal from '@/components/common/MessageModal.vue'
import UserToolbar from '@/components/UserToolbar.vue'
import { AuthActionTypes } from '@/store/modules/auth/interfaces/action-types'
import { LinksActionType } from '@/store/modules/links/interfaces'
import { setI18nLanguage } from '@/i18n'
import Error401Page from './pages/Error401Page.vue'
import BeforeLoginLayout from '@/components/layout/BeforeLoginLayout.vue'
import { LanguagesType } from '@/types'
import { HelpPagesActionType } from '@/store/modules/help-pages/interfaces'
import AppGlobalModal from '@/components/app/AppGlobalModal.vue'
import ModalComponent from './components/ModalComponent.vue'
import { AuthMutationTypes } from '@/store/modules/auth/interfaces/mutation-types'
import { RolePermissionDetails } from './store/modules/users/interfaces/IUser'
import { IAuthState } from './store/modules/auth/IAuthState'
import { IFetchSearchResponse } from '@/store/modules/main/intefaces/fetchSearch/IFetchSearchResponse'
import { IPortalHardCodeLink } from './store/modules/links/interfaces/IPortalHardCodeLink'
import { getValueByLabel } from './utils/permissions'
import AppLayout from '@/components/layout/AppLayout.vue'
import { getUnseenHelpPages } from './utils/newCapabilities'

export default defineComponent({
  name: 'App',

  components: {
    TutorialModal,
    Error401Page,
    MessageModal,
    UserToolbar,
    BeforeLoginLayout,
    ModalComponent,
    AppGlobalModal,
    ResizeObserver,
    AppLayout
  },
  data: () => ({
    showTutorialModal: false,
    gigICode: '',
    gigIvToken: '',
    isPortalChrome: true,
    isLoaded: false,
    openModal: false,
    message: '',
    subMessage: '',
    searchValue: '',
    searchValues: [] as IFetchSearchResponse[],
    isErrorModal: true,
    activeLanguage: (localStorage.getItem('lang')) as LanguagesType,
    clientWidth: 0,
    dictionary: {
      en: {
        createAnOrder: 'CREATE AN ORDER',
        createAnOrderIt: 'Create An Order',
        cart: 'MY CART',
        otherLinks: 'Other links',
        legalClause: 'Legal clause',
        backUp: 'Back up',
        menu: 'Menu',
        tutorial: 'Tutorial',
        search: 'Search',
        home: 'Homepage',
        profile: 'My Profile',
        contracts: 'Contracts',
        orders: 'Orders',
        history: 'History',
        savedOrders: 'Saved Orders',
        suggestedCart: 'Suggested Cart',
        catalog: 'Catalogue',
        products: 'Product Overview',
        favorites: 'My Favorites',
        favoritesMenu: 'MY FAVORITES',
        reporting: 'Reporting',
        myDocuments: 'My documents',
        marketingMaterials: 'Marketing Materials',
        news: 'News and Articles',
        newsEvents: 'News and Events',
        aboutPeroni: 'About Peroni',
        support: 'Support',
        faq: 'FAQ',
        knowledgeBase: 'Knowledge Base',
        supportTickets: 'Support Tickets',
        eLearning: 'Academy',
        skELearning: 'Academy',
        pubMenu: 'Menu Creator',
        skPubMenu: 'Menu Creator',
        emptiesManagement: 'Empties Management',
        contacts: 'Contacts',
        about: 'About us',
        cookies: 'Cookies and personal data protection',
        brands: 'Brands',
        productCode: 'Product code',
        createNewUser: 'Create New User',
        trainingLearning: 'Training & Learning',
        Unit: 'Unit',
        actions: {
          edit: 'My Profile',
          logout: 'Logout'
        },
        languages: {
          cs: 'Česky',
          en: 'English',
          de: 'Deutsch',
          it: 'Italiano',
          sk: 'Slovensky'
        },
        login: 'Login',
        notificationsEmpty: 'No notifications',
        termsOfUse: 'Terms of use',
        corporateInformation: 'Corporate Information',
        corporateGovernance: 'Corporate Governance',
        goToCart: 'Go To Cart',
        removeItem: 'Remove Item'
      },
      cs: {
        createAnOrder: 'Vytvořit objednávku',
        administration: 'Administrace',
        cart: 'Košík',
        home: 'Hlavní stránka',
        profile: 'Můj profil',
        contracts: 'Přehled smluv',
        orders: 'Objednávky',
        history: 'Historie objednávek',
        savedOrders: 'Uložené objednávky',
        suggestedCart: 'Návrh objednávky',
        catalog: 'Katalog',
        products: 'Přehled výrobků',
        favorites: 'Moje oblíbené',
        favoritesMenu: 'Moje oblíbené',
        reporting: 'Přehled odběrů',
        myDocuments: 'Moje dokumenty',
        marketingMaterials: 'Materiály pro Vás',
        news: 'Novinky a články',
        aboutPeroni: 'O Peroni',
        support: 'Podpora',
        faq: 'Otázky a odpovědi',
        knowledgeBase: 'O portálu',
        supportTickets: 'Kontaktujte nás',
        eLearning: 'Akademie První u zdroje',
        pubMenu: 'Prazdroj menu',
        otherLinks: 'Další odkazy',
        legalClause: 'Právní doložka',
        backUp: 'Zpět nahoru',
        menu: 'Menu',
        tutorial: 'Průvodce',
        search: 'Vyhledat',
        contacts: 'Kontakty',
        about: 'O nás',
        cookies: 'Cookies a ochrana osobních údajů',
        brands: 'Značky',
        productCode: 'kód produktu',
        createNewUser: 'Vytvořit nového uživatele',
        Unit: 'Balení',
        actions: {
          edit: 'Profil',
          logout: 'Odhlásit'
        },
        languages: {
          cs: 'Česky',
          en: 'English',
          de: 'Deutsch',
          it: 'Italiano',
          sk: 'Slovensky'
        },
        login: 'Přihlásit se',
        notificationsEmpty: 'Žádné notifikace',
        termsOfUse: 'Podmínky použití',
        goToCart: 'Košík',
        removeItem: 'Odstranit'
      },
      sk: {
        createAnOrder: 'Vytvoriť objednávku',
        administration: 'Administrace',
        cart: 'Košík',
        home: 'Hlavná stránka',
        profile: 'Môj profil',
        contracts: 'Prehľad zmlúv',
        orders: 'Objednávky',
        history: 'História objednávok',
        savedOrders: 'Uložené objednávky',
        suggestedCart: 'Odporúčaný tovar',
        catalog: 'Katalóg',
        products: 'Prehľad výrobkov',
        favorites: 'Moje obľúbené',
        favoritesMenu: 'Moje obľúbené',
        reporting: 'Prehľad odberov',
        myDocuments: 'Moje dokumenty',
        marketingMaterials: 'Materiály pre Vás',
        news: 'Novinky a články',
        aboutPeroni: 'O Peroni',
        support: 'Podpora',
        faq: 'Otázky a odpovede',
        knowledgeBase: 'O portáli',
        supportTickets: 'Kontaktujte nás',
        eLearning: 'Akadémia Prvý pri zdroji',
        skELearning: 'Akadémia Prvý pri zdroji',
        pubMenu: 'Prazdroj menu',
        skPubMenu: 'Prazdroj menu',
        otherLinks: 'Ďalšie odkazy',
        legalClause: 'Právna doložka',
        backUp: 'Späť hore',
        menu: 'Menu',
        tutorial: 'Sprievodca',
        search: 'Hľadať',
        contacts: 'Kontakty',
        about: 'O nás',
        cookies: 'Cookies a ochrana osobných údajov',
        brands: 'Značky',
        productCode: 'kód produktu',
        createNewUser: 'Vytvoriť nového užívateľa',
        Unit: 'Balenie',
        actions: {
          edit: 'Profil',
          logout: 'Odhlásiť'
        },
        languages: {
          cs: 'Česky',
          en: 'English',
          sk: 'Slovensky'
        },
        login: 'Prihlásiť sa',
        notificationsEmpty: 'Žiadne notifikácie',
        termsOfUse: 'Podmienky používania',
        goToCart: 'Košík',
        removeItem: 'Odstrániť'
      }
    },
    timeoutId: 0
  }),

  computed: {
    ...mapState('main', ['pageNotFound']),
    ...mapState('basket', ['id', 'items', 'progressBarMsg']),
    ...mapGetters('main', ['getPermissions']),
    portalHardCodeLink (): IPortalHardCodeLink[] {
      return this.$store.state.links.portalHardCodeLink?.results ?? []
    },
    total () {
      return this.$store.state.links.portalHardCodeLink.total
    },
    portalPageMenuLink () {
      return this.$store.state.links.portalPageMenuLink?.items ?? []
    },
    limit () {
      return this.$store.state.links.portalHardCodeLink.limit
    },
    queries () {
      return this.$store.state.catalog.queries
    },
    catalogPermission () {
      return this.checkPermission('Catalogue', {}).length !== 0
    },

    languages () {
      if (this.user.country === 'CZE' || window.location.hostname.includes('.cz')) {
        return ['cs', 'en']
      }

      if (this.user.country === 'SVK' || window.location.hostname.includes('.sk')) {
        return ['sk', 'en']
      }

      return ['en', this.activeLanguage].filter((x, index, self) => x != null && self.indexOf(x) === index)
    },

    user () {
      return this.$store.state.auth.user
    },

    isLoggedIn () {
      return this.user.isLoggedIn // && !!this.user.userValidation
    },
    notAuthorized () {
      return this.$route.meta?.requireAuthentication && !this.isLoggedIn
    },
    allPermission () {
      return this.user.allAvailablePermission
    },
    helpPagesStore () {
      return this.$store.state.helpPages
    },
    customerType (): RolePermissionDetails | undefined {
      if (this.user && this.user?.permissionSchema?.length > 0) {
        return this.user?.permissionSchema[0]?.rpDetails?.find((item: RolePermissionDetails) => item.customerType)
      }
      return undefined
    },
    isPOSAcademyUser () {
      return this.user.permissionSchema?.every(permission => permission.rpDetails.every(
        roleItem =>
          roleItem.rolename === 'POS Academy' ||
          roleItem.roleid === 'f5ab4859-9197-47e5-bc9e-097283c35d94'
      ))
    }
  },

  watch: {
    isLoggedIn: {
      handler (value) {
        if (value === true) {
          this.fetchPortalHardCodeLink()
          this.fetchPortalPageMenuLink()
        }
      }
    },
    activeLanguage: {
      handler (value) {
        this.setMagentoStore(value)
        if (this.isLoggedIn) {
          this.fetchPortalHardCodeLink()
          this.fetchPortalPageMenuLink()
        }
      },
      immediate: true
    },

    $route () {
      this.initFromRoute()
    },

    progressBarMsg (value, oldValue) {
      if (oldValue !== value) {
        this.$store.commit('basket/SET_PROGRESS_BAR', value)
        return value
      }
    }
  },

  created () {
    this.initFromRoute()
  },

  async mounted () {
    if (window.location.hostname.includes('.cz') && this.activeLanguage !== 'en') {
      this.changeLanguage('cs')
    }

    if (window.location.hostname.includes('.sk') && this.activeLanguage !== 'en') {
      this.changeLanguage('sk')
    }

    this.checkGigyaReady()
    this.hasProductRecommendationPermission()
  },

  methods: {

    checkGigyaReady () {
      if (window.gigya) {
        window.gigya.hasSession().then(this.checkSession)
        window.gigya.socialize.addEventHandlers({
          onLogin: async (res: IAuthState) => {
            await this.updateUserInfo(res)
            await this.$store.dispatch('basket/CREATE_EMPTY_CART', { root: true })
          }
        })
      } else {
        setTimeout(() => {
          this.checkGigyaReady()
        }, 100)
      }
    },

    initFromRoute () {
      this.isPortalChrome = !this.$route.meta?.noPortalChrome
      const urlParams = new URLSearchParams(window.location.search)
      this.gigICode = urlParams.get('gig_i_code') || ''
      this.gigIvToken = urlParams.get('gig_i_vToken') || ''
    },
    checkPermission (permission: string, value: Record<string, any>, additionalPremmision = true) {
      if (this.customerType) localStorage.setItem('customertype', this.customerType?.customerType)

      return this.allPermission?.includes(permission) &&
      additionalPremmision &&
      getValueByLabel(this.getPermissions(), permission)
        ? [value]
        : []
    },
    hasProductRecommendationPermission () {
      const hasPermission = this.user.permissionSchema?.every(permission => permission.rpDetails.every(
        roleItem =>
          roleItem.permission === 'Product Recommendation'
      ))

      if (hasPermission) {
        localStorage.setItem('productrecoenabled', '1')
      }

      return hasPermission
    },
    async dispatchHelpPages () {
      await this.$store.dispatch(HelpPagesActionType.FETCH_ALL_HELP_PAGES)
    },
    openTutorialModal () {
      this.showTutorialModal = true
    },
    closeTutorialModal () {
      this.showTutorialModal = false
    },
    changeLanguage (langCode: LanguagesType) {
      this.activeLanguage = langCode
      setI18nLanguage(langCode)
    },
    fetchPortalHardCodeLink () {
      this.$store.dispatch(LinksActionType.FETCH_PORTAL_HARD_CODE_LINK)
    },
    fetchPortalPageMenuLink () {
      this.$store.dispatch(LinksActionType.FETCH_PAGE_MENU_LINK)
    },

    getLinkByName (name: string) {
      const linkObj = this.portalHardCodeLink.find((link) => link.name === name)
      return linkObj ? { url: linkObj.url, label: linkObj.label } : { url: '', label: '' }
    },

    /**
     * Method to update logged in user info into vuex store
     * @param res
     */
    async updateUserInfo (res: IAuthState) {
      sessionStorage.setItem('uid', res.UID) // This is for Google Analytics
      sessionStorage.setItem('getUserInfoResponse', JSON.stringify(res))
      this.$store.commit('SET_AUTH_USER', res)
      if (this.isLoggedIn) {
        this.setupTimers()
      }
      await this.$store.dispatch(AuthActionTypes.GET_USER_INFO, { id: res.UID }).then(() => {
        if (!this.user.userValidation && !this.$route.meta?.isUserVerification) {
          this.userLogout()
          return
        }

        if (this.user.country === 'CZE' && this.activeLanguage !== 'en') {
          this.changeLanguage('cs')
        }

        if (this.user.country === 'SVK' && this.activeLanguage !== 'en') {
          this.changeLanguage('sk')
        }
        if (this.user.country === 'POL' && this.activeLanguage !== 'en') {
          this.changeLanguage('en')
        }

        this.setMagentoStore(this.activeLanguage)
      })

      if (!!this.user.userValidation && !this.$route.meta?.isUserVerification) {
        await this.checkPOSAcademyUser()
      }

      if (
        !(this.helpPagesStore !== undefined && this.helpPagesStore.helpPages && this.helpPagesStore.helpPages.length)
      ) {
        await this.dispatchHelpPages()

        if ((this.user.firstLogin === true) ||
        (this.user.showTutorial > 0) ||
         (this.user.showTutorial === null) ||
      (getUnseenHelpPages(this.helpPagesStore.helpPages.filter(h => h?.newCapability === true), this.user.UID).length > 0)
        ) {
          this.openTutorialModal()
        }
      }

      if (localStorage.getItem('customertype') && localStorage.getItem('shippingMethod')) {
        this.$store.dispatch('main/FETCH_ORDERABLE_UNITS_CONFIG',
          {
            customerType: localStorage.getItem('customertype'),
            orderType: localStorage.getItem('shippingMethod')
          }, { root: true })
      }
    },
    checkPOSAcademyUser () {
      if (this.isPOSAcademyUser) {
        window.gigya.fidm.saml.initSSO({
          spName: this.user.country === 'SVK' ? 'skELearning' : 'eLearning',
          redirectURL: this.user.country === 'SVK' ? this.getLinkByName('skELearning').url : this.getLinkByName('eLearning').url
        })
      }
    },
    /**
     * Method to initiate logout action
     * on click action of logout button
     */
    userLogout () {
      sessionStorage.removeItem('uid')
      sessionStorage.removeItem('imp_usr')
      sessionStorage.removeItem('isFromFavoritePage')
      const params = {
        UID: this.user.UID,
        callback: this.handleLogout
      }
      window.gigya.accounts.logout(params)
    },
    /**
     * Method to perform action after user logs out
     * Navigate user to home page
     */
    handleLogout () {
      this.$store.dispatch('logout')
      window.location.reload()
    },
    /**
     * Method to check user session if it exist
     * if it does not exist then redirect user to login page
     * @param sessionExist
     */
    async checkSession (sessionExist: boolean) {
      try {
        if (sessionExist) {
          window.gigya.socialize.getUserInfo({ callback: this.updateUserInfo })
        } else {
          if (!this.$route.meta?.isUserVerification && this.$route.meta?.requireAuthentication) {
            this.$navigate('/login')
          }
        }
      } catch (error) {
        console.error('Error in checkSession:', error)
      } finally {
        this.isLoaded = true
      }
    },

    setMagentoStore (language: string) {
      if (this.user.country === 'SVK' || (this.user.country == null && window.location.hostname.includes('.sk'))) {
        localStorage.setItem('store', `LivePortal_SK_${language}`)
      }
      if (this.user.country === 'CZE' || (this.user.country == null && window.location.hostname.includes('.cz'))) {
        localStorage.setItem('store', `LivePortal_CZ_${language}`)
      }
      if (this.user.country === 'POL' || (this.user.country == null && window.location.hostname.includes('.cz'))) {
        localStorage.setItem('store', `LivePortal_PL_${language}`)
      }

      if (!(this.user.country == null || this.user.country === '') && !this.$store.state.auth.isLanguageLoaded) {
        this.$store.commit(AuthMutationTypes.SET_LANGUAGE_LOADED, true)
      }
    },

    onResizePortal ({ height }: { height: number }) {
      this.clientWidth = document.querySelector('body')?.clientWidth || height
    },
    startTimer () {
      this.timeoutId = setTimeout(this.userLogout, 3600000)
    },
    resetTimer () {
      clearTimeout(this.timeoutId)
      this.startTimer()
    },
    setupTimers () {
      document.addEventListener('keypress', this.resetTimer, false)
      document.addEventListener('mousemove', this.resetTimer, false)
      document.addEventListener('mousedown', this.resetTimer, false)
      document.addEventListener('touchmove', this.resetTimer, false)

      this.startTimer()
    }
  }
})
