import axios from 'axios'
import { constants } from '@/constants'

const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    timeout: 55000
  })
  instance.interceptors.request.use(
    (configParam) => {
      const idToken = sessionStorage.getItem('id_token')
      configParam.headers.Authorization = idToken
      const language = localStorage.getItem('lang')
      configParam.params = { language, ...configParam.params }
      configParam.headers['x-market'] = (process.env.VUE_APP_MARKET) ? process.env.VUE_APP_MARKET : ''
      configParam.headers.exceptiondate = localStorage.getItem('exceptiondate') ?? 0
      // console.info('interceptors', configParam.params.language, configParam.headers.Authorization)
      return configParam
    },

    (error) => {
      return Promise.reject(error)
    }
  )
  return instance
}
const createAxiosInstance_V2 = (baseURL: string) => {
  const instance_v2 = axios.create({
    baseURL,
    timeout: 55000
  })
  instance_v2.interceptors.request.use(
    (configParam) => {
      const idToken = sessionStorage.getItem('id_token')
      configParam.headers.Authorization = idToken
      const language = localStorage.getItem('lang')
      configParam.headers['x-market'] = process.env.VUE_APP_MARKET
      configParam.params = { language, ...configParam.params }
      configParam.headers.exceptiondate = localStorage.getItem('exceptiondate') ?? 0
      // console.info('interceptors', configParam.params.language, configParam.headers.Authorization)
      return configParam
    },

    (error) => {
      return Promise.reject(error)
    }
  )
  return instance_v2
}

export const cdcServiceAPI = createAxiosInstance(constants.api.cdcService)
export const magnoliaServiceAPI = createAxiosInstance(constants.api.magnoliaService)
export const generalServiceV1API = createAxiosInstance(constants.api.generalservicev1)
export const generalServiceV2API = createAxiosInstance_V2(constants.api.generalservicev2)
