import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ba6d2c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-row" }
const _hoisted_2 = { class: "grid-cell" }
const _hoisted_3 = { class: "grid-cell" }
const _hoisted_4 = { class: "grid-cell change-old-value" }
const _hoisted_5 = { class: "grid-cell change-new-value" }
const _hoisted_6 = { class: "grid-cell change-order-by" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsahiTypography = _resolveComponent("AsahiTypography")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AsahiTypography, {
        class: "grid-cell",
        type: "subtitle-bold"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Change type')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_AsahiTypography, {
        class: "grid-cell",
        type: "subtitle-bold"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Order type')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_AsahiTypography, {
        class: "grid-cell",
        type: "subtitle-bold"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Original delivery')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_AsahiTypography, {
        class: "grid-cell",
        type: "subtitle-bold"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Next delivery')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_AsahiTypography, {
        class: "grid-cell",
        type: "subtitle-bold"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Order by')), 1)
        ]),
        _: 1
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.changes, (change, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "grid-row"
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(change.typeOfChange)), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(change.transportation) + ' ' + _ctx.$t('(including empties return)')), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.formatDate(change.originalDate)) + " " + _toDisplayString(change.originalHours ? ' ' + change.originalHours : ''), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.formatDate(change.scheduledDate)) + " " + _toDisplayString(change.scheduledHour ? ' ' + change.scheduledHour : ''), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatDate(change.orderBy)), 1)
      ]))
    }), 128))
  ], 64))
}