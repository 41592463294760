import { generalServiceV1API } from '@/store/api-instance'
import { CalendarExceptions, FetchedExceptions } from '@/store/modules/deliveryDates/interfaces/calendarException'

export async function fetchExceptions (guid: string, shipTo: string, store: string): Promise<CalendarExceptions[]> {
  try {
    const { data }: {
      data: FetchedExceptions
    } = await generalServiceV1API.get('/orders/GetDeliveryExceptionNotifications', { params: { guid, shipTo, store } })
    return data.filter((item: CalendarExceptions | null) => item !== null && item.deliveryExceptions.length !== 0) as CalendarExceptions[]
  } catch (ex) {
    console.error(ex)
    return []
  }
}
