import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cfbc1222"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "select-label fw-bold"
}
const _hoisted_2 = { class: "select-icon" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["title"]
const _hoisted_5 = {
  key: 0,
  class: "exclamation-icon"
}
const _hoisted_6 = { class: "arrow-container" }
const _hoisted_7 = {
  key: 0,
  class: "exclamation-icon"
}
const _hoisted_8 = { class: "selected-option-mobile-inner" }
const _hoisted_9 = { class: "label-image-container-mobile" }
const _hoisted_10 = { class: "select-icon" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 0,
  class: "calendar-exception-note"
}
const _hoisted_13 = { class: "exclamation-icon-dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptionsModal = _resolveComponent("OptionsModal")!
  const _component_BasicModal = _resolveComponent("BasicModal")!
  const _component_ExclamationIcon = _resolveComponent("ExclamationIcon")!
  const _component_AsahiArrow = _resolveComponent("AsahiArrow")!
  const _component_AsahiTypography = _resolveComponent("AsahiTypography")!
  const _component_AsahiRadioButton = _resolveComponent("AsahiRadioButton")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showOptionsModal)
      ? (_openBlock(), _createBlock(_component_OptionsModal, {
          key: 0,
          name: _ctx.name,
          selectedValue: _ctx.modelValue,
          options: _ctx.filteredOptions,
          isWhite: true,
          onChooseOption: _ctx.selectOption,
          icon: _ctx.icon,
          "close-modal": () => _ctx.setOptionsModalTo(false),
          title: _ctx.title,
          subtitle: _ctx.displayLabel || _ctx.placeholder,
          description: _ctx.selectedOption?.description,
          hasNote: _ctx.hasCalendarException,
          "note-content": _ctx.calendarExceptionNote
        }, null, 8, ["name", "selectedValue", "options", "onChooseOption", "icon", "close-modal", "title", "subtitle", "description", "hasNote", "note-content"]))
      : _createCommentVNode("", true),
    (_ctx.showSelectedModal)
      ? (_openBlock(), _createBlock(_component_BasicModal, {
          key: 1,
          icon: _ctx.icon,
          "close-modal": () => _ctx.setSelectedModalTo(false),
          title: _ctx.title,
          subtitle: _ctx.displayLabel || _ctx.placeholder,
          description: _ctx.selectedOption?.description
        }, null, 8, ["icon", "close-modal", "title", "subtitle", "description"]))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["select-box", {'select-box-mobile': _ctx.isMobile}])
    }, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass({'select-container': _ctx.isTicket, 'ticket-container pe-4': !_ctx.isTicket})
      }, [
        (!_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["selected-option d-flex", { 'invalid': !_ctx.isValid, 'valid': _ctx.isValid }]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
            }, [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.icon)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.icon,
                      alt: "icon",
                      class: "option-icon"
                    }, null, 8, _hoisted_3))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", {
                class: _normalizeClass({'selected-text fw-bold': _ctx.isTicket, 'ticket-label fw-bold': !_ctx.isTicket}),
                title: _ctx.displayLabel
              }, _toDisplayString(_ctx.displayLabel || _ctx.placeholder), 11, _hoisted_4),
              (_ctx.hasCalendarException)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_ExclamationIcon)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_AsahiArrow, {
                  "arrow-color": 'white',
                  "is-rotated": !_ctx.openDropdown
                }, null, 8, ["is-rotated"])
              ])
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["selected-option d-flex", { 'invalid': !_ctx.isValid, 'valid': _ctx.isValid, 'selected-option-mobile': _ctx.isMobile }]),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleContainerClick && _ctx.handleContainerClick(...args)))
            }, [
              (_ctx.hasCalendarException)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_ExclamationIcon)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    (_ctx.icon)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _ctx.icon,
                          alt: "icon",
                          class: "option-icon"
                        }, null, 8, _hoisted_11))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass([{'selected-text': _ctx.isTicket, 'ticket-label': !_ctx.isTicket}, "fs-14"])
                  }, _toDisplayString(_ctx.displayLabel || _ctx.placeholder), 3)
                ]),
                _createElementVNode("div", {
                  class: "arrow-container",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setOptionsModalTo(true)))
                }, [
                  _createVNode(_component_AsahiArrow, {
                    class: _normalizeClass({ 'horizontalArrow' : _ctx.isMobile }),
                    "arrow-color": 'white',
                    "is-rotated": !_ctx.openDropdown
                  }, null, 8, ["class", "is-rotated"])
                ])
              ])
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.openDropdown)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(["triangle-up", { 'white-bg': _ctx.isWhite }])
            }, null, 2))
          : _createCommentVNode("", true),
        (_ctx.openDropdown)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: _normalizeClass(["options-container py-3", { 'white-bg': _ctx.isWhite, 'no-offset':_ctx.noOffset }])
            }, [
              (_ctx.hasCalendarException)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_ExclamationIcon)
                    ]),
                    _createVNode(_component_AsahiTypography, { type: "subtitle-1" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.calendarExceptionNote), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_AsahiRadioButton, {
                name: _ctx.name,
                selectedValue: _ctx.modelValue,
                options: _ctx.filteredOptions,
                isWhite: true,
                hasCalendarException: true,
                note: _ctx.calendarExceptionNote,
                "show-note": _ctx.hasCalendarException,
                onChooseOption: _ctx.selectOption
              }, null, 8, ["name", "selectedValue", "options", "note", "show-note", "onChooseOption"])
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)), [
      [_directive_click_outside, _ctx.handleClickOutside]
    ])
  ], 64))
}