import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a2243d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalComponent = _resolveComponent("ModalComponent")!
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modals, (modal) => {
      return (_openBlock(), _createBlock(_component_ModalComponent, {
        key: modal.id,
        ref_for: true,
        ref: _ctx.setModalRef,
        showCloseButton: modal.showCloseButton ?? true,
        backdropDismiss: modal.backdropDismiss ?? false,
        isFullScreenMobile: modal.props?.isFullScreenMobile ?? true ,
        onOnClose: () => modal.onClose()
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(modal.component), _mergeProps({ closeModal: _ctx.closeModal }, modal.props, _toHandlers(modal.listeners)), null, 16, ["closeModal"]))
        ]),
        _: 2
      }, 1032, ["showCloseButton", "backdropDismiss", "isFullScreenMobile", "onOnClose"]))
    }), 128)),
    (_ctx.showLoader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_LoaderSpinner)
        ]))
      : _createCommentVNode("", true)
  ]))
}