
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import formatDateMixin from '@/utils/mixin'
import { isExceptionDeliveryDate, isStandardDeliveryDate } from '@/utils/deliveryDate'
import AsahiButton from '@/components/library/AsahiButton.vue'
import shipToIcon from '@/components/library/assets/shipTo.svg'
import trackIcon from '@/components/library/assets/Truck.svg'
import CalendarIcon from '@/assets/icons/calendar-white.svg'
import AsahiTypography from '@/components/library/Typography.vue'
import closebadge from '@/assets/icons/library/closebadge.svg'

export default defineComponent({
  name: 'ContinueShopping',

  loadingIndicators: true,

  components: {
    AsahiButton,
    AsahiTypography
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {
        /* */
      }
    }
  },

  mixins: [formatDateMixin],

  inject: ['isPeroni'],

  data () {
    return {
      continueValue: 'continue',
      newValue: 'new',
      shipToIcon,
      trackIcon,
      CalendarIcon,
      deliveryDateSelected: '',
      selectedValue: 'continue',
      closebadge
    }
  },

  emits: ['doSelection'],

  computed: {
    ...mapState('basket', ['query', 'items', 'id']),
    ...mapState('catalog', ['shipTos', 'continueModalTitle', 'continueModalSubTitle', 'continueModalContinueMessage', 'continueModalNewMessage']),

    shipToName () {
      const { permissionSchema = [] } = this.$store.state.auth.user || {}
      return permissionSchema.find((permission: any) => permission.shipToId === this.query?.ship_to_id)?.shipToName
    }
  },

  methods: {
    async startYourOrder () {
      if (!this.shipTos.length) await this.$store.dispatch('catalog/FETCH_SHIP_TO', { ship_to_id: this.query.ship_to_id }, { root: true })

      this.$nextTick(async () => {
        const defaultNonEmptyTransportType = ['flatrate_flatrate', 'selfpickup_selfpickup']

        defaultNonEmptyTransportType.push('freeshipping_freeshipping')

        const backUpDeliveryDate = localStorage.getItem('deliveryDate')
        const backUpStandardDeliveryDate = localStorage.getItem('standardDeliveryDate')
        const backUpShippingMethod = localStorage.getItem('shippingMethod')
        const backUpOnlyEmpties = localStorage.getItem('onlyEmpties')

        localStorage.setItem('deliveryDate', this.query.delivery_date)
        localStorage.setItem('',
          Number(isExceptionDeliveryDate(this.$store.state.deliveryDates.calendarException,
            localStorage.getItem('shipToId') ?? '',
            this.query.delivery_date, this.query.delivery_method)).toString())
        localStorage.setItem(
          'standardDeliveryDate',
          Number(isStandardDeliveryDate(this.query.delivery_date, this.query.delivery_method, this.shipTos)).toString()
        )

        localStorage.setItem('shippingMethod', this.query.delivery_method)

        if (defaultNonEmptyTransportType.includes(this.query.delivery_method)) {
          localStorage.setItem('onlyEmpties', JSON.stringify(0))
        }

        if (!defaultNonEmptyTransportType.includes(this.query.delivery_method)) {
          localStorage.setItem('onlyEmpties', JSON.stringify(1))
          if (this.query.note_for_driver != null) localStorage.setItem('noteForDriver', this.query.note_for_driver)
          if (this.query.customer_order_number != null) {
            localStorage.setItem('customerOrderNumber', this.query.customer_order_number)
          }
        }

        const newDeliveryDate = localStorage.getItem('deliveryDate')
        const newStandardDeliveryDate = localStorage.getItem('standardDeliveryDate')
        const newShippingMethod = localStorage.getItem('shippingMethod')
        const newpOnlyEmpties = localStorage.getItem('onlyEmpties')

        if (backUpDeliveryDate !== newDeliveryDate || backUpStandardDeliveryDate !== newStandardDeliveryDate || backUpShippingMethod !== newShippingMethod || backUpOnlyEmpties !== newpOnlyEmpties) {
          this.fetchProducts()
        }
        this.closeModal()
      })
    },

    async fetchProducts () {
      try {
        await this.$store.dispatch('catalog/FETCH_PRODUCTS', {}, { root: true })
      } catch (error) {
        console.error(error)
      }
    },

    async removeItemInner (id: string) {
      await this.$store.dispatch('basket/REMOVE_ITEM', { cart_id: this.id, cart_item_id: id }, { root: true })
    },

    async cancel () {
      await Promise.all(this.items?.map((product: any) => this.removeItemInner(product.id)))
      await this.$store.dispatch('basket/FETCH_CART_QUERY', { cart_id: this.id }, { root: true })
      this.$emit('doSelection')
      this.closeModal()
    },

    select (value: string) {
      this.selectedValue = value
    },

    isSelected (value:string) {
      return this.selectedValue === value
    },

    proceed () {
      if (this.selectedValue === this.continueValue) {
        this.startYourOrder()
      } else {
        this.cancel()
      }
    }
  }
})
