
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import AsahiSelectHeader from '@/components/library/AsahiSelectHeader.vue'
import AsahiProgressBar from '@/components/library/assets/AsahiProgressBar.vue'
import { permissionSchema } from '@/store/modules/users/interfaces/IUser'
import { getLocaleKeyForTransportationTypes, mapTransportType, TransportationTypes } from '@/utils/transportTypes'
import { IAuthUser } from '@/store/modules/auth/interfaces/IAuthUser'
import { IShipToBySapId } from '@/store/modules/catalog/interfaces/shipto/IShipToResponse'
import DeliveryDatePicker from '@/components/cart/DeliveryDatePicker.vue'
import {
  getHolidayDays,
  isExceptionDeliveryDate,
  isStandardDeliveryDate,
  validateDeliveryDate
} from '@/utils/deliveryDate'
import shipToIcon from '@/components/library/assets/shipTo.svg'
import trackIcon from '@/components/library/assets/Truck.svg'
import calendarIcon from '@/components/library/assets/CalendarWhite.svg'
import { BaseCartQueryItem } from '@/store/modules/basket/interfaces/cartQuery/ICartQueryResponse'
import ContinueShoppingModal from '@/components/modals/ContinueShoppingModal.vue'
import modals from '@/utils/modals'
import formatDateMixin from '@/utils/mixin'
import AsahiButton from '@/components/library/AsahiButton.vue'
import AngleUpDownIcon from '@/components/library/icons/AngleUpDownIcon.vue'
import DeliveredIcon from '@/components/library/icons/DeliveredIcon.vue'
import { CalendarExceptions, ShipToOptions } from '@/store/modules/deliveryDates/interfaces/calendarException'
import ExclamationIcon from '@/components/library/icons/ExclamationIcon.vue'

const MORE_SECTION_META_VALUES = ['marketing', 'news']

const SHIP_TO_IDS_FOR_SMALL_TRUCK = [
  '1123069', '1128540', '1128541', '1132153', '1378446', '1451527', '1078585',
  '1106931', '1155500', '1147836', '1146023', '1153964', '1085003', '1084773',
  '1075622', '1093683', '1100456', '1579306', '1158332', '1539502', '1156832',
  '1604126', '1128540', '1128541', '1129556', '1129606', '1413065', '1080720',
  '1152086', '1152468', '1152760', '1273610', '1156069', '1149863', '1089260',
  '1104514', '1416172', '1266582', '1080721', '1320552', '1066949', '1148930',
  '1168627', '1113875', '1125857', '1375235', '1108869', '1438029', '1131934',
  '1132317', '1095627', '1112567', '1151988', '1521734', '1542465', '1115492',
  '1115496', '1115660', '1141697', '1142453', '1123069', '1063410', '1703422',
  '1063964', '1146401', '1073245', '1157566', '1064844', '1111689', '1155548',
  '1148854', '1148931', '1148984', '1117107', '1111029', '1143037', '1062195',
  '1144026', '1145460', '1146945', '1067872', '1266603', '1158660', '1152186',
  '1100463', '1132153', '1098807', '1150786', '1468275', '1542466', '1155717',
  '1102307', '1148668', '1090907', '1394830', '1170955', '1158810', '1151697',
  '1106753', '1090103', '1102368', '1127244', '1098841', '1129290', '1708572',
  '1266598', '1266585', '1163069', '1139246', '1085432', '1140959', '1155526',
  '1155525', '1266600', '1155499', '1605455', '1142682', '1084086', '1245819',
  '1173894', '1184254', '1184315', '1269775', '1063796', '1274183', '1275964',
  '1542467', '1335755', '1351099', '1353708', '1378446', '1548509', '1451527',
  '1465188', '1561033', '1469448', '1542464', '1535243', '1541037', '1543956',
  '1549221', '1554637', '1558094', '1561116', '1572062', '1573557', '1612569',
  '1576040', '1458396', '1600847', '1602373', '1602391', '1603558', '1604231',
  '1612578', '1616018', '1619699', '1701405', '1704801', '1707777', '1465230'
]

export default defineComponent({
  components: { ExclamationIcon, DeliveredIcon, AngleUpDownIcon, AsahiButton, DeliveryDatePicker, AsahiSelectHeader, AsahiProgressBar },
  mixins: [formatDateMixin],
  props: {
    changeMessageColor: {
      type: Boolean,
      default: false
    },
    filterTransportations: {
      type: Array as () => Array<TransportationTypes>,
      default: undefined
    },
    isSuggestedCart: {
      type: Boolean,
      default: false
    },
    suggestedShipToId: {
      type: String,
      default: null
    },
    cartId: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      shipToIcon,
      trackIcon,
      calendarIcon,
      selectedShipmentId: '',
      errorMsg: '',
      successWeight: false,
      errorWeight: false,
      shipmentSet: false,
      selectedOrderType: 'selfpickup_selfpickup',
      deliveryDateSelected: '',
      shipTo: {},
      billingAddressForm: {} as any,
      options: [] as any,
      deliveryDatePickerHeight: 0,
      isExpanded: false,
      bufferedSelection: {} as any,
      isDatePickerOpen: false,
      orderInfo: null as any | null
    }
  },
  computed: {
    ...mapState('basket', ['id', 'query', 'shipmentId', 'deliveryDate', 'items', 'paymentMethod', 'availableDate', 'customerSettings']),
    ...mapState('catalog', ['alignment', 'shipTos']),
    ...mapState('main', ['holidaysConfig']),
    ...mapState('auth', ['user']),
    ...mapState('deliveryDates', ['availableDays', 'filteredDeliveryDates']),

    calendarException () {
      return this.$store.state.deliveryDates.calendarException
    },

    isMoreSection (): boolean {
      return MORE_SECTION_META_VALUES.includes(this.$route?.meta?.tabSelection as string)
    },
    formattedDeliveryDate () {
      if (!this.deliveryDate) return ''
      const args = this.deliveryDate.split('-')
      return args[2] + '.' + args[1]
    },
    currentShippingPlace () {
      return this.shipToOptions.find((s: any) => s.value === this.selectedShipmentId)
    },
    currentShippingMethod () {
      return this.transportOptionsAsSelectBoxOptions.find((t: any) => t.value === this.shipmentId)
    },

    shipToOptions (): {label: string, value: string, description: string, hasCalendarException: boolean}[] {
      const { permissionSchema = [] } = this.user || {}
      return permissionSchema
        .filter((permission: any) =>
          permission.rpDetails.some(
            (role: any) =>
              role.permission.includes('Catalogue') &&
              permission.shipToStatus === 'APPROVED'
          )
        )
        .map((permission: any) => ({
          label: permission.shipToName,
          value: permission.shipToId,
          description: permission?.shipToAddress,
          hasCalendarException: this.hasShipToCalendarException(permission)
        }))
    },
    hasSelectedShipmentCalendarException () {
      return this.shipToOptions.some(
        (option) => option.value === this.selectedShipmentId && option.hasCalendarException
      )
    },
    progressBar () {
      return this.query?.progress_bar_data
    },
    maxOrderCash () {
      return this.progressBar?.iMaximumOrderCash ?? 0
    },
    grandTotalYourPrice () {
      return this.query?.grand_total_your_price_details?.grand_total_your_price ?? 0
    },
    cashFilledPercentage () {
      return (this.grandTotalYourPrice / this.maxOrderCash) * 100
    },
    messageColorClass () {
      let msgColor = ''
      let borderColor = ''
      if (this.changeMessageColor) {
        if (this.errorWeight) {
          msgColor = 'error-text'
          borderColor = 'border-error-text'
        } else if (this.successWeight) {
          msgColor = 'success-text'
          borderColor = 'border-success-text'
        } else {
          msgColor = 'default-text'
          borderColor = 'border-default-text'
        }
      }
      return { msgColor, borderColor }
    },
    filteredTransportOptions () {
      if (this.filterTransportations && this.filterTransportations.length) {
        return this.transportOptions.filter((option: any) => {
          if (this.isSuggestedCart &&
            (option.value === 'emptiespickup_emptiespickup' ||
              option.value === 'emptiesfulltruck_emptiesfulltruck')) {
            return false
          } else {
            return this.filterTransportations?.includes(option.value)
          }
        })
      } else {
        return this.transportOptions.filter((option: any) => {
          return !(this.isSuggestedCart &&
            (option.value === 'emptiespickup_emptiespickup' ||
              option.value === 'emptiesfulltruck_emptiesfulltruck'))
        })
      }
    },
    transportOptions () {
      if (this.shipTos?.length > 0) {
        const customerType = this.shipTos.length > 0
          ? this.user.permissionSchema.filter((item: any) => item.shipToId === this.shipTos[0].shiptosapid).map((permission: any) => {
            return {
              value: permission.rpDetails[0].customerType
            }
          })
          : ''

        const fullTruck = this.shipTos.find((item: IShipToBySapId) => item.type === 'PD')
        const smallTruck = this.shipTos.find((item: IShipToBySapId) => item.type !== null && item.type.includes('1'))
        const transports = [] as any

        if (SHIP_TO_IDS_FOR_SMALL_TRUCK.includes(this.selectedShipmentId)) {
          transports.push({
            label: this.$t(getLocaleKeyForTransportationTypes(TransportationTypes.SMALL_TRUCK)),
            value: TransportationTypes.SMALL_TRUCK,
            checked: this.selectedOrderType === 'freeshipping_freeshipping',
            hasCalendarException: this.hasCalendarExceptionForCurrentSelection(mapTransportType(TransportationTypes.SMALL_TRUCK))
          })
        }
        if (fullTruck) {
          transports.push(
            {
              label: this.$t(getLocaleKeyForTransportationTypes(TransportationTypes.TRUCK)),
              value: TransportationTypes.TRUCK,
              checked: this.selectedOrderType === 'flatrate_flatrate',
              hasCalendarException: this.hasCalendarExceptionForCurrentSelection(mapTransportType(TransportationTypes.TRUCK))
            }
          )
          if (!(this.user.country === 'SVK' && customerType[0]?.value === 'POS')) {
            transports.push(
              {
                label: this.$t(getLocaleKeyForTransportationTypes(TransportationTypes.EMPTY_TRUCK)),
                value: TransportationTypes.EMPTY_TRUCK,
                checked: this.selectedOrderType === 'emptiesfulltruck_emptiesfulltruck',
                hasCalendarException: this.hasCalendarExceptionForCurrentSelection(mapTransportType(TransportationTypes.EMPTY_TRUCK))
              }
            )
          }
        }
        if (smallTruck) {
          transports.push(
            {
              label: this.$t(getLocaleKeyForTransportationTypes(TransportationTypes.SMALL)),
              value: TransportationTypes.SMALL,
              checked: this.selectedOrderType === 'selfpickup_selfpickup',
              hasCalendarException: this.hasCalendarExceptionForCurrentSelection(mapTransportType(TransportationTypes.SMALL))
            },
            {
              label: this.$t(getLocaleKeyForTransportationTypes(TransportationTypes.EMPTY_SMALL)),
              value: TransportationTypes.EMPTY_SMALL,
              checked: this.selectedOrderType === 'emptiespickup_emptiespickup',
              hasCalendarException: this.hasCalendarExceptionForCurrentSelection(mapTransportType(TransportationTypes.EMPTY_SMALL))
            }
          )
        }
        return transports
      }
      return [
        {
          label: this.$t(getLocaleKeyForTransportationTypes(TransportationTypes.TRUCK)),
          value: TransportationTypes.TRUCK,
          checked: this.selectedOrderType === 'flatrate_flatrate',
          hasCalendarException: this.hasCalendarExceptionForCurrentSelection(mapTransportType(TransportationTypes.TRUCK))
        },
        {
          label: this.$t(getLocaleKeyForTransportationTypes(TransportationTypes.SMALL_TRUCK)),
          value: TransportationTypes.SMALL_TRUCK,
          checked: this.selectedOrderType === 'freeshipping_freeshipping',
          hasCalendarException: this.hasCalendarExceptionForCurrentSelection(mapTransportType(TransportationTypes.SMALL_TRUCK))
        },
        {
          label: this.$t(getLocaleKeyForTransportationTypes(TransportationTypes.SMALL)),
          value: TransportationTypes.SMALL,
          checked: this.selectedOrderType === 'selfpickup_selfpickup',
          hasCalendarException: this.hasCalendarExceptionForCurrentSelection(mapTransportType(TransportationTypes.SMALL))
        },
        {
          label: this.$t(getLocaleKeyForTransportationTypes(TransportationTypes.EMPTY_SMALL)),
          value: TransportationTypes.EMPTY_SMALL,
          checked: this.selectedOrderType === 'emptiespickup_emptiespickup',
          hasCalendarException: this.hasCalendarExceptionForCurrentSelection(mapTransportType(TransportationTypes.EMPTY_SMALL))
        },
        {
          label: this.$t(getLocaleKeyForTransportationTypes(TransportationTypes.EMPTY_TRUCK)),
          value: TransportationTypes.EMPTY_TRUCK,
          checked: this.selectedOrderType === 'emptiesfulltruck_emptiesfulltruck',
          hasCalendarException: this.hasCalendarExceptionForCurrentSelection(mapTransportType(TransportationTypes.EMPTY_TRUCK))
        }
      ]
    },
    transportOptionsAsSelectBoxOptions () {
      return this.transportOptions.map((option: any) => ({
        label: option.label,
        value: option.value,
        hasCalendarException: option.hasCalendarException
      }))
    },
    hasSelectedTransportCalendarException () {
      return this.transportOptions.some(
        (option: any) => option.value === this.selectedOrderType && option.hasCalendarException
      )
    },
    cartItems () {
      let cartItems: Array<{productid: string}> = []
      if (this.items.length) {
        cartItems = this.items.map((item: BaseCartQueryItem) => {
          return { productId: item.product.sku }
        })
      }
      return cartItems
    },
    isOnHomepage () {
      return this.$route.path === '/'
    },
    defaultShipTo () {
      return this.$store.state.auth.defaultShipTo
    },
    shipToOptionsForExceptions (): ShipToOptions {
      const { permissionSchema = [] } = this.user || {}
      return permissionSchema
        .filter((permission: any) =>
          permission.rpDetails.some((role: any) => role.permission.includes('Catalogue') && permission.shipToStatus === 'APPROVED')
        )
        .map((permission: any) => ({
          label: permission.shipToName,
          shipToId: permission.shipToId,
          description: permission?.shipToAddress
        }))
    },
    shipToForExceptions (): string {
      return this.shipToOptionsForExceptions.map((item: any) => item.shipToId).join(',')
    },
    hasAnyShipToException (): boolean {
      return this.calendarException && this.calendarException.length > 0
    }
  },
  methods: {
    handleDatePickerOpen () {
      this.isDatePickerOpen = true
    },
    handleDatePickerClose () {
      this.isDatePickerOpen = false
    },
    toggleAccordion (event: any) {
      this.isExpanded = event.target.getAttribute('aria-expanded') === 'true'
    },
    setShipmentWrapper (value: string) {
      if (this.items?.length > 0) {
        this.setContinueModalMessages(
          this.$t('You have a basket in progress for a ship to with the date of delivery', { place: this.currentShippingPlace?.description || '', date: this.formateDateWithoutLocale(this.deliveryDateSelected) }),
          this.$t('Continue ordering with current ship to.', { place: this.currentShippingPlace?.description || '' }),
          this.$t('Start a new order with new ship to . Products from the cart will be removed.', { place: this.getShippingDescription(value) }))
        this.bufferedSelection = { type: 'setShipment', value }
        return this.openOrderModal()
      } else {
        this.setContinueModalMessages('', '', '')
        this.setShipment(value, true)
      }
    },

    getShippingDescription (value: string) {
      const shipTo = this.shipToOptions.find((option: any) => option.value === value)
      return shipTo?.description || ''
    },

    async setShipment (value: string, isDropDownChange = false) {
      this.errorMsg = ''
      this.selectedShipmentId = value
      this.errorMsg = ''
      if (this.checkDisableShipTo(this.user, this.selectedShipmentId)) {
        this.errorMsg = this.$t('ShipTo suspended - Select another ShipTo')
        return
      }
      try {
        await this.$store.commit('basket/SET_LOADING', true, { root: true })
        await this.$store.dispatch(
          'catalog/FETCH_SHIP_TO',
          { ship_to_id: this.selectedShipmentId },
          { root: true }
        )
        if (this.shipTos) {
          this.shipmentSet = true
          this.setDefaultTransportType()
          this.onOrderTransportTypeSelection(this.selectedOrderType, isDropDownChange)
        }
        if (!this.isOnHomepage) {
          await this.$store.dispatch('catalog/FETCH_RECOMMENDED_PRODUCTS', {
            shipTo: this.selectedShipmentId,
            cartItems: this.cartItems
          }, { root: true })
        }
      } catch (error) {
        console.error('shipment error', error)
        this.errorMsg = 'Error setting shipment'
      } finally {
        await this.$store.commit('basket/SET_LOADING', false, { root: true })
      }

      const companyId = this.getCompanyid(this.selectedShipmentId, this.user)
      const event = new CustomEvent('setShipment', {
        detail: {
          type: 'delivery-options',
          shipTo: this.selectedShipmentId,
          payer: companyId
        }
      })
      document.dispatchEvent(event)
    },
    setDefaultTransportType () {
      const alreadySelectedShippingMethod = localStorage.getItem('shippingMethod') ?? ''
      if (alreadySelectedShippingMethod !== 'undefined' && alreadySelectedShippingMethod !== undefined && alreadySelectedShippingMethod !== '' && alreadySelectedShippingMethod !== null) {
        this.selectedOrderType = alreadySelectedShippingMethod
      } else if (this.filteredTransportOptions.map((option: any) => option.value).includes(TransportationTypes.SMALL)) {
        this.selectedOrderType = 'selfpickup_selfpickup'
      } else {
        this.selectedOrderType = this.filteredTransportOptions[0].value
      }
    },
    getCompanyid (shipToId: string, user: IAuthUser) {
      let companyId = ''
      const shipToItem = user?.permissionSchema.find((item: permissionSchema) => item.shipToId === shipToId)
      if (shipToItem) {
        companyId = shipToItem?.companyId
      }
      return companyId
    },
    checkDisableShipTo (user: IAuthUser, shipToId: string) {
      const permissionSchema = user?.permissionSchema || []

      if (shipToId != null) {
        return permissionSchema.some(
          (shipToItem: permissionSchema) => shipToItem.shipToId === shipToId && shipToItem.shipToStatus === 'SUSPENDED'
        )
      }

      if (permissionSchema.length === 1) {
        return permissionSchema[0]?.shipToStatus === 'SUSPENDED'
      }

      return false
    },
    async startYourOrder () {
      const errorMsg = []
      if (this.selectedOrderType === '') errorMsg.push(this.$t('Order type has to not be empty!'))
      if (this.deliveryDateSelected === '') errorMsg.push(this.$t('Delivery date has to not be empty!'))
      if (errorMsg.length > 0) {
        // this.errorMsg = errorMsg[0]
        return
      }
      if (!validateDeliveryDate(this.deliveryDateSelected, this.selectedOrderType, this.shipTos, getHolidayDays(this.holidaysConfig)) &&
      !isExceptionDeliveryDate(this.$store.state.deliveryDates.calendarException, localStorage.getItem('shipToId') ?? '', this.deliveryDateSelected, this.selectedOrderType as TransportationTypes)) {
        (this.$refs.deliveryDatePickerRef as InstanceType<typeof DeliveryDatePicker>)?.forceRecalculate()
        errorMsg.push(this.$t('Shipping cannot be ordered. Choose a different date.'))
      }
      if (errorMsg.length > 0) {
        this.errorMsg = errorMsg[0]
        return
      }

      // get new orderable units based on new order type
      this.$store.dispatch('main/FETCH_ORDERABLE_UNITS_CONFIG',
        {
          customerType: localStorage.getItem('customertype'),
          orderType: this.selectedOrderType
        }, { root: true })
      localStorage.setItem('deliveryDate', this.deliveryDateSelected)
      localStorage.setItem('exceptiondate',
        Number(isExceptionDeliveryDate(this.$store.state.deliveryDates.calendarException,
          localStorage.getItem('shipToId') ?? '',
          this.deliveryDateSelected, this.selectedOrderType as TransportationTypes)).toString())
      localStorage.setItem(
        'standardDeliveryDate',
        Number(isStandardDeliveryDate(this.deliveryDateSelected, this.selectedOrderType, this.shipTos)).toString()
      )
      this.$store.commit('basket/SET_DELIVERY_DATE', this.deliveryDateSelected, { root: true })
      await this.$store.dispatch(
        'basket/FETCH_TRANSPORT_TYPE',
        { transporttype: this.selectedOrderType, cart_id: this.id },
        { root: true }
      )

      try {
        if (this.isSuggestedCart) {
          this.onProceedWithSuggestedCart()
        } else {
          const ajaxRequests = [
            this.$store.dispatch('basket/FETCH_MIN_CART_QUERY_FOR_CATALOG', { cart_id: this.id }, { root: true })
          ]
          if (!this.isOnHomepage) {
            ajaxRequests.push(this.$store.dispatch('catalog/FETCH_PRODUCTS', {}, { root: true }))
          }

          if (this.$store.state.catalog.openedProduct != null) {
            ajaxRequests.push(this.$store.dispatch('catalog/FETCH_PRODUCT', { sku: this.$store.state.catalog.openedProduct.sku }, { root: true }))
          }

          ajaxRequests.push(await this.$store.dispatch('catalog/FETCH_RECOMMENDED_PRODUCTS', {
            shipTo: this.selectedShipmentId,
            cartItems: this.cartItems
          }, { root: true }))

          await Promise.all(ajaxRequests)

          if (this.isOnHomepage) {
            this.$store.dispatch('catalog/FETCH_WISHLIST', { bypassloading: true }, { root: true })
          }
        }
      } catch (error) {
        console.error('order modal error', error)
      }
    },
    async initDefaultShipmentId () {
      const selectedShipTo = localStorage.getItem('shipToId') ?? ''
      if (this.shipToOptions.length > 0 && selectedShipTo === '') {
        this.selectedShipmentId = this.shipToOptions[0].value
      } else {
        this.selectedShipmentId = selectedShipTo
      }
      this.setShipment(this.selectedShipmentId)
      if (this.holidaysConfig == null || this.holidaysConfig?.holidays?.length === 0) await this.$store.dispatch('main/FETCH_HOLIDAYS_CONFIG', { root: true })
    },

    onOrderTransportTypeSelectionWrapper (value: string) {
      const defaultNonEmptyTransportType = ['flatrate_flatrate', 'selfpickup_selfpickup', 'freeshipping_freeshipping']
      if (defaultNonEmptyTransportType.includes(value)) {
        localStorage.setItem('onlyEmpties', JSON.stringify(0))
      } else {
        localStorage.setItem('onlyEmpties', JSON.stringify(1))
      }
      if (this.items?.length > 0) {
        this.bufferedSelection = { type: 'onOrderTransportTypeSelection', value }
        return this.openOrderModal()
      } else {
        this.onOrderTransportTypeSelection(value, true)
      }
    },
    async onOrderTransportTypeSelection (value: string, isDropDownChange = false) {
      const alreadySetDeliveryDate = localStorage.getItem('deliveryDate') ?? ''
      const fromLocal = localStorage.getItem('shipToId') || this.shipToOptions[0].value
      const selectedShipTo = fromLocal || this.selectedShipmentId
      this.selectedOrderType = value
      await this.$store.commit('basket/SET_SHIPMENT_ID', this.selectedOrderType, { root: true })
      const storeId = localStorage.getItem('store')
      if (this.selectedOrderType != null && storeId != null && selectedShipTo) {
        await this.$store.dispatch('deliveryDates/GET_DELIVERY_AVAILABLE_DATE', {
          shipTo: selectedShipTo,
          shipmentId: this.selectedOrderType,
          store: storeId
        })

        // Check is already selected delivery date exist in available days array
        const doesExistPreSelectedDate = this.availableDays.filter((d: string) => alreadySetDeliveryDate !== '' && d.includes(alreadySetDeliveryDate))
        if (doesExistPreSelectedDate.length > 0 && !isDropDownChange) {
          this.deliveryDateSelected = alreadySetDeliveryDate
        } else {
          const firstAvailableDate = this.filteredDeliveryDates.length > 0 ? this.filteredDeliveryDates[0] : new Date().toISOString()
          if (!alreadySetDeliveryDate && this.customerSettings && this.customerSettings?.delivery_date) {
            this.deliveryDateSelected = this.customerSettings?.delivery_date
          } else {
            this.deliveryDateSelected = firstAvailableDate.slice(0, 10)
          }
        }

        this.startYourOrder()
        await this.$store.commit('basket/SET_TRANSPORT_TYPE', {
          transporttype: this.computedSelectedOrderType
        })
        await this.$store.dispatch('catalog/FETCH_FAVORITES', { pageSize: 25 }, { root: true })
        await this.$store.dispatch('catalog/FETCH_PRODUCTS', { root: true })
      }
    },

    openOrderModal () {
      modals.showModal({
        component: ContinueShoppingModal,
        listeners: {
          doSelection: () => {
            this.doSelection()
            this.setContinueModalMessages('', '', '')
          }
        }
      })
    },

    setContinueModalMessages (title: string, continueMsg: string, newMsg: string) {
      this.$store.commit('catalog/SET_CONTINUE_MODAL_TITLE', title)
      this.$store.commit('catalog/SET_CONTINUE_MODAL_CONTINUE_MESSAGE', continueMsg)
      this.$store.commit('catalog/SET_CONTINUE_MODAL_NEW_MESSAGE', newMsg)
    },

    doSelection () {
      if (this.bufferedSelection?.type && this.bufferedSelection.value) {
        switch (this.bufferedSelection?.type) {
          case 'onOrderTransportTypeSelection' :
            this.onOrderTransportTypeSelection(this.bufferedSelection.value, true)
            break
          case 'setShipment' :
            this.setShipment(this.bufferedSelection.value, true)
            break
          case 'onDateSelection' :
            this.onDateSelection(this.bufferedSelection.value)
            break
        }
      }
      this.bufferedSelection = {}
    },

    async onProceedWithSuggestedCart () {
      await this.$store.dispatch('basket/CREATE_SUGGESTED_CART', {
        shipTo: this.selectedShipmentId,
        cartId: ''
      }, { root: true })
    },
    onDateSelectionWrapper (valueWrapper: any) {
      const value = typeof valueWrapper === 'string' ? valueWrapper : valueWrapper.value
      this.deliveryDateSelected = localStorage.getItem('deliveryDate') ?? this.filteredDeliveryDates[0]
      if (this.items?.length > 0 && !valueWrapper.isFirstAvailable) {
        (this.$refs.deliveryDatePickerRef as InstanceType<typeof DeliveryDatePicker>)?.forceRecalculate()
        this.setContinueModalMessages(
          this.$t('You have a basket in progress for a collection point with the date of delivery', { place: this.currentShippingPlace?.description || '', date: this.formateDateWithoutLocale(this.deliveryDateSelected) }),
          this.$t('Continue ordering with current date'),
          this.$t('Start a new order with new delivery date. Products from the cart will be removed.', { date: this.formateDateWithoutLocale(value) }))
        this.bufferedSelection = { type: 'onDateSelection', value }
        return this.openOrderModal()
      } else {
        this.setContinueModalMessages('', '', '')
        this.onDateSelection(value)
      }
    },
    onDateSelection (date: string) {
      this.deliveryDateSelected = date
      localStorage.setItem('deliveryDate', this.deliveryDateSelected)
      localStorage.setItem('exceptiondate',
        Number(isExceptionDeliveryDate(this.$store.state.deliveryDates.calendarException,
          localStorage.getItem('shipToId') ?? '',
          this.deliveryDateSelected, this.selectedOrderType as TransportationTypes)).toString())
      if (!this.selectedOrderType) {
        this.selectedOrderType = localStorage.getItem('shippingMethod') ?? ''
      }
      localStorage.setItem(
        'standardDeliveryDate',
        Number(isStandardDeliveryDate(this.deliveryDateSelected, this.selectedOrderType, this.shipTos)).toString()
      )
      this.$store.commit('basket/SET_DELIVERY_DATE', this.deliveryDateSelected, { root: true })
      this.startYourOrder()
    },
    computedSelectedOrderType (): string {
      if (!this.orderInfo || !this.orderInfo.deliveryTransportId) return ''

      switch (this.orderInfo.deliveryTransportId) {
        case 'YDOR':
          return 'selfpickup_selfpickup'
        case 'YSE1':
          return 'emptiesfulltruck_emptiesfulltruck'
        case 'YDE1':
          return 'emptiespickup_emptiespickup'
        case 'YSOR':
          return 'flatrate_flatrate'
        default:
          return ''
      }
    },

    async displayCalendarExceptionModal () {
      await modals.showCalendarExceptionModal(this.calendarException)
    },

    hasCalendarExceptionForCurrentSelection (transportType: string): boolean {
      const selected = this.shipToOptions.find(option => option.value === this.selectedShipmentId)

      if (transportType === mapTransportType(TransportationTypes.EMPTY_SMALL) ||
      transportType === mapTransportType(TransportationTypes.SMALL_TRUCK)) {
        return this.hasCalendarExceptionForCurrentSelection(mapTransportType(TransportationTypes.SMALL))
      }

      if (transportType === mapTransportType(TransportationTypes.EMPTY_TRUCK)) {
        return this.hasCalendarExceptionForCurrentSelection(mapTransportType(TransportationTypes.TRUCK))
      }

      return (selected?.hasCalendarException && this.calendarException.some(
        (exception: CalendarExceptions) => exception.shipToId === selected.value && exception.deliveryExceptions.some((delivery) => delivery.transportation === transportType))) || false
    },

    hasShipToCalendarException (permission: any) {
      return this.calendarException.some(
        (exception: any) =>
          exception.deliveryExceptions?.some((transportation: any) =>
            transportation.deliveryExceptions?.some(
              (de: any) => de.shipToId === permission.shipToId
            )
          )
      )
    }

  },
  async mounted () {
    const defaultShipmentId = this.defaultShipTo ?? ''
    if (defaultShipmentId) {
      localStorage.setItem('shipToId', defaultShipmentId)
    }
    this.initDefaultShipmentId()
    this.$store.commit('basket/SET_SHIPMENT_ID', this.selectedOrderType, { root: true })
    await this.$store.dispatch('deliveryDates/FETCH_CALENDAR_EXCEPTION', {
      guid: this.user.UID || '',
      shipTo: this.shipToForExceptions,
      store: localStorage.getItem('store') || ''
    })
  },
  watch: {
    availableDate (newDate) {
      this.deliveryDate = newDate
    }
  }
})
