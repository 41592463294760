import { IHolidaysConfig } from '@/store/modules/main/intefaces/IConfig'
import { IShipToBySapId } from '@/store/modules/catalog/interfaces/shipto/IShipToResponse'
import { CalendarExceptions } from '@/store/modules/deliveryDates/interfaces/calendarException'
import { mapTransportType, TransportationTypes, TRUCK_TRANSPORTATIONS } from '@/utils/transportTypes'
import { IAuthUser } from '@/store/modules/auth/interfaces/IAuthUser'

export const AVAILABLE_NEXT_DAYS = 365

const HOLIDAY_DAYS_CZ = [
  `${new Date().getFullYear()}-01-01`,
  `${new Date().getFullYear()}-04-07`,
  `${new Date().getFullYear()}-04-10`,
  `${new Date().getFullYear()}-05-01`,
  `${new Date().getFullYear()}-05-08`,
  `${new Date().getFullYear()}-07-05`,
  `${new Date().getFullYear()}-07-06`,
  `${new Date().getFullYear()}-09-28`,
  `${new Date().getFullYear()}-10-28`,
  `${new Date().getFullYear()}-11-17`,
  `${new Date().getFullYear()}-12-24`,
  `${new Date().getFullYear()}-12-25`,
  `${new Date().getFullYear()}-12-26`
]

const HOLIDAY_DAYS_SK = [
  `${new Date().getFullYear()}-01-01`,
  `${new Date().getFullYear()}-01-06`,
  `${new Date().getFullYear()}-04-07`,
  `${new Date().getFullYear()}-04-10`,
  `${new Date().getFullYear()}-05-01`,
  `${new Date().getFullYear()}-05-08`,
  `${new Date().getFullYear()}-07-05`,
  `${new Date().getFullYear()}-08-29`,
  `${new Date().getFullYear()}-09-01`,
  `${new Date().getFullYear()}-09-15`,
  `${new Date().getFullYear()}-11-01`,
  `${new Date().getFullYear()}-11-17`,
  `${new Date().getFullYear()}-12-24`,
  `${new Date().getFullYear()}-12-25`,
  `${new Date().getFullYear()}-12-26`
]

export const getHolidayDays = (holidays?: IHolidaysConfig[]) => {
  if (holidays != null && holidays?.length > 0) {
    let result = [] as any
    for (const value of holidays?.values()) {
      result = Object.values(value)
    }
    const final = result?.map((item: string) => item.includes(',') ? item.split(',') : item)
      .filter((item: string) => item)
      .filter((i: string) => i !== 'Months').flat()
    return final.map((holidays: string) => holidays.trim()).sort()
  } else {
    return localStorage.getItem('store')?.includes('SK') ? HOLIDAY_DAYS_SK : HOLIDAY_DAYS_CZ
  }
}

export const getUTCDate = (date: Date): string => {
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
}

const isWorkingDay = (date: Date, holidayDays: string[] = []): boolean => {
  const day = date.getDay()
  return !(day === 6 || day === 0 || holidayDays.includes(getUTCDate(date)))
}

const getFirstWorkingFromStartDay = (startDay: number, holidayDays: string[] = [], _counter = 0): number => {
  const date = new Date()
  date.setDate(date.getDate() + _counter)

  if (!isWorkingDay(date, holidayDays)) {
    return getFirstWorkingFromStartDay(startDay, holidayDays, _counter + 1)
  }

  if (startDay > 0) {
    return getFirstWorkingFromStartDay(startDay - 1, holidayDays, _counter + 1)
  }

  return isWorkingDay(new Date(), holidayDays) ? _counter : _counter - 1
}

export const getStartDate = (shipmentId: string, holidayDays: string[] = []): number => {
  const todayIsNotWorkingDay = !isWorkingDay(new Date(), holidayDays)

  let startDayCZ = 0
  if (shipmentId === 'flatrate_flatrate' || shipmentId === 'emptiesfulltruck_emptiesfulltruck') {
    const toDay1330 = new Date()
    toDay1330.setHours(13, 30, 0, 0)

    startDayCZ = todayIsNotWorkingDay || new Date().getTime() >= toDay1330.getTime() ? 3 : 2
  }
  if (
    shipmentId === 'selfpickup_selfpickup' ||
    shipmentId === 'emptiespickup_emptiespickup' ||
    shipmentId === 'freeshipping_freeshipping'
  ) {
    startDayCZ = todayIsNotWorkingDay || new Date().getHours() >= 16 ? 2 : 1
  }

  return getFirstWorkingFromStartDay(startDayCZ, holidayDays)
}

const getMinAndMaxDays = (startDay: number, availableNextDays: number) => {
  const newStartDate = new Date()
  newStartDate.setDate(newStartDate.getDate() + startDay)
  const minDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth(), newStartDate.getDate(), 0, 0, 0)

  const newEndDate = new Date()
  newEndDate.setDate(newEndDate.getDate() + availableNextDays)
  const maxDate = new Date(newEndDate.getFullYear(), newEndDate.getMonth(), newEndDate.getDate(), 23, 59, 59)

  return { minDate, maxDate }
}

export const getMinAndMaxDayStrings = (startDay: number, availableNextDays: number) => {
  const { minDate, maxDate } = getMinAndMaxDays(startDay, availableNextDays)
  return { minDate: getUTCDate(minDate), maxDate: getUTCDate(maxDate) }
}

const convertWeekDays = (days: string[]) => {
  if (!Array.isArray(days)) return []
  const week = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  return days.map((day: string) => week.indexOf(day))
}

export const getDeliveryDaysOnWeek = (shipmentId: string, shipTos: IShipToBySapId[] = []): number[] => {
  if (shipTos) {
    const smallTruck = shipTos?.find((transport: IShipToBySapId) => transport?.type?.includes('1'))
    const fullTruck = shipTos?.find((transport: IShipToBySapId) => transport?.type === 'PD')

    if (
      shipmentId === 'selfpickup_selfpickup' ||
      shipmentId === 'emptiespickup_emptiespickup' ||
      shipmentId === 'freeshipping_freeshipping'
    ) {
      if (smallTruck && smallTruck?.delivery_days.length > 0) {
        return convertWeekDays(smallTruck.delivery_days)
      }
      if (smallTruck && smallTruck?.small_truck_sec_Days.length > 0) {
        return convertWeekDays(smallTruck.small_truck_sec_Days)
      }
    }
    if (shipmentId === 'flatrate_flatrate' || shipmentId === 'emptiesfulltruck_emptiesfulltruck') {
      if (fullTruck && fullTruck?.delivery_days.length > 0) {
        return convertWeekDays(fullTruck.delivery_days)
      }
      if (fullTruck && fullTruck?.small_truck_sec_Days.length > 0) {
        return convertWeekDays(fullTruck.small_truck_sec_Days)
      }
    }
  }

  return []
}

export const isExceptionDeliveryDate = (
  calendarExceptions: CalendarExceptions[],
  shipToId: string,
  date: string,
  transportationId: TransportationTypes
): boolean => {
  if (date === '' || shipToId === '') {
    return false
  }
  const mappedTransport = _mappedShipmentForException(transportationId)
  return calendarExceptions.some(entry =>
    entry.deliveryExceptions.some(transport =>
      transport.transportation === mappedTransport &&
      transport.deliveryExceptions.some(exception =>
        exception.shipToId === shipToId && _isSameDate(exception.scheduledDate, date)
      )
    )
  )
}

const _isSameDate = (dateA: string, dateB: string): boolean => {
  const parsedA = new Date(dateA)
  const parsedB = new Date(dateB)
  return (
    parsedA.getFullYear() === parsedB.getFullYear() &&
    parsedA.getMonth() === parsedB.getMonth() &&
    parsedA.getDate() === parsedB.getDate()
  )
}

export const isStandardDeliveryDate = (deliveryDate: string, shipmentId: string, shipTos: IShipToBySapId[] = []): boolean => {
  const deliveryDaysOnWeek = getDeliveryDaysOnWeek(shipmentId, shipTos)
  return deliveryDaysOnWeek.includes(new Date(deliveryDate).getDay())
}

export const validateDeliveryDate = (
  deliveryDate: string,
  shipmentId: string,
  shipTos: IShipToBySapId[] = [],
  holidayDays: string[],
  availableNextDays: number = AVAILABLE_NEXT_DAYS
) => {
  if (deliveryDate == null || deliveryDate === '') return false

  if (holidayDays.includes(deliveryDate)) return false

  const _deliveryDate = new Date(deliveryDate)

  const deliveryDaysOnWeek = getDeliveryDaysOnWeek(shipmentId, shipTos)
  const workingDaysOnWeek = convertWeekDays(['MON', 'TUE', 'WED', 'THU', 'FRI'])
  const availableDaysOnWeek = [...deliveryDaysOnWeek, ...workingDaysOnWeek]

  if (!availableDaysOnWeek.includes(_deliveryDate.getDay())) return false

  const startDay = getStartDate(shipmentId, holidayDays)
  const { minDate, maxDate } = getMinAndMaxDays(startDay, availableNextDays)

  return _deliveryDate.getTime() >= minDate.getTime() && _deliveryDate.getTime() <= maxDate.getTime()
}

export const filterCalendarExceptions = (
  calendarExceptions: CalendarExceptions[]
): CalendarExceptions[] => {
  return calendarExceptions.map(({ shipTo, shipToId, deliveryExceptions }: CalendarExceptions) => ({
    shipTo,
    shipToId,
    deliveryExceptions: deliveryExceptions
      .map(({ transportation, deliveryExceptions }) => {
        const isTruck = TRUCK_TRANSPORTATIONS.includes(transportation)
        const filtered = deliveryExceptions.filter(exception =>
          isTruck ? (exception.truckorder === true || exception.truckorder === 'True') : (exception.standardorder === true || exception.standardorder === 'True')
        )

        return {
          transportation,
          deliveryExceptions: filtered
        }
      })
      .filter(trans => trans.deliveryExceptions.length > 0)
  })).filter(entry => entry.deliveryExceptions.length > 0)
}

export const hasDeliveryException = (shipToId: string, shipmentId: TransportationTypes, exceptions: CalendarExceptions[]) : boolean => {
  const mappedTransport = _mappedShipmentForException(shipmentId)

  return exceptions.some(exception =>
    exception.shipToId === shipToId &&
    exception.deliveryExceptions?.some(delivery =>
      delivery.transportation === mappedTransport &&
      delivery.deliveryExceptions?.length > 0
    )
  )
}

export const exceptionForDate =
  (shipToId: string, shipmentId:
  TransportationTypes,
  date: string,
  exceptions: CalendarExceptions[]) => {
    if (!shipToId || !shipmentId || !date || !exceptions.length) return null

    const transportName = _mappedShipmentForException(shipmentId)

    for (const calendarEntry of exceptions) {
      if (calendarEntry.shipToId !== shipToId) continue

      for (const delivery of calendarEntry.deliveryExceptions) {
        if (delivery.transportation !== transportName) continue

        const match = delivery.deliveryExceptions.find(exception =>
          _isSameDate(exception.scheduledDate, date)
        )

        if (match) return match
      }
    }

    return null
  }

export const shipToForExceptions = (user: IAuthUser): string => {
  const { permissionSchema = [] } = user || {}
  return permissionSchema
    .filter((permission: any) =>
      permission.rpDetails.some((role: any) => role.permission.includes('Catalogue') && permission.shipToStatus === 'APPROVED')
    )
    .map((permission: any) => ({
      label: permission.shipToName,
      shipToId: permission.shipToId,
      description: permission?.shipToAddress
    })).map((item: any) => item.shipToId).join(',')
}

const _mappedShipmentForException = (shipmentId: TransportationTypes) : string => {
  return mapTransportType(_getExceptionShipment(shipmentId))
}

const _getExceptionShipment = (shipmentId: TransportationTypes): TransportationTypes => {
  if (shipmentId === TransportationTypes.EMPTY_TRUCK) {
    return TransportationTypes.TRUCK
  } else if (
    shipmentId === TransportationTypes.EMPTY_SMALL ||
    shipmentId === TransportationTypes.SMALL_TRUCK
  ) {
    return TransportationTypes.SMALL
  }
  return shipmentId
}

export const getShipmentForExceptions = (shipmentId: TransportationTypes) => {
  return _getExceptionShipment(shipmentId)
}

export const sortByDateAsc = (arr: string[]) => {
  return arr.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
}
