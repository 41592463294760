export enum TransportationTypes {
  TRUCK = 'flatrate_flatrate',
  SMALL_TRUCK = 'freeshipping_freeshipping',
  SMALL = 'selfpickup_selfpickup',
  EMPTY_SMALL = 'emptiespickup_emptiespickup',
  EMPTY_TRUCK = 'emptiesfulltruck_emptiesfulltruck'
}

export enum OrderTypes {
  TRUCK = 'TRUCK',
  SMALL_TRUCK = 'SMALL_TRUCK',
  SMALL = 'SMALL',
  EMPTY_SMALL = 'EMPTY_SMALL',
  EMPTY_TRUCK = 'EMPTY_TRUCK'
}

export enum SapTransportId {
  YSE1 = 'YSE1', // Label: Full truck return
  YDE1 = 'YDE1', // Label: Regular return
  YDOR = 'YDOR', // Label: Regular order
  YSOR = 'YSOR', // Label: Full truck order
  YDER = 'YDER', // Label: Regular return
  YSER = 'YSER' // Label: Full truck return
}

export const TRUCK_TRANSPORTATIONS = ['Truck', 'Empty Truck']

export function mapTransportType (type: string) {
  const transportMap: { [key: string]: string } = {
    freeshipping_freeshipping: 'Small truck',
    flatrate_flatrate: 'Truck',
    selfpickup_selfpickup: 'Standard Order',
    emptiesfulltruck_emptiesfulltruck: 'Empty Truck',
    emptiespickup_emptiespickup: 'Empty Small'
  }
  return transportMap[type] || type
}

export function getLocaleKeyForTransportationTypes (transportationTypes: TransportationTypes) {
  let value = ''
  switch (transportationTypes) {
    case TransportationTypes.TRUCK:
      value = 'Transport_Type_Truck'
      break
    case TransportationTypes.SMALL_TRUCK:
      value = 'Transport_Type_Small_Truck'
      break
    case TransportationTypes.SMALL:
      value = 'Transport_Type_Small'
      break
    case TransportationTypes.EMPTY_SMALL:
      value = 'Transport_Type_Empty_Small'
      break
    case TransportationTypes.EMPTY_TRUCK:
      value = 'Transport_Type_Empty_Truck'
      break
    default:
      break
  }
  return value
}

export function getOrderTypeForUnitsConfig (transportationType: TransportationTypes): OrderTypes {
  switch (transportationType) {
    case TransportationTypes.TRUCK:
      return OrderTypes.TRUCK
    case TransportationTypes.SMALL_TRUCK:
      return OrderTypes.SMALL // by definition SMALL_TRUCK should retrieve the same Magento config as SMALL order
    case TransportationTypes.SMALL:
      return OrderTypes.SMALL
    case TransportationTypes.EMPTY_SMALL:
      return OrderTypes.EMPTY_SMALL
    case TransportationTypes.EMPTY_TRUCK:
      return OrderTypes.EMPTY_TRUCK
    default:
      return transportationType
  }
}

export function mapSAPTransportIdToTransportationTypes (sapId: SapTransportId): TransportationTypes {
  switch (sapId) {
    case SapTransportId.YSE1: // Label: Full truck return
      return TransportationTypes.EMPTY_TRUCK
    case SapTransportId.YDE1: // Label: Regular return
      return TransportationTypes.EMPTY_SMALL
    case SapTransportId.YDOR: // Label: Regular order
      return TransportationTypes.SMALL
    case SapTransportId.YSOR: // Label: Full truck order
      return TransportationTypes.TRUCK
    case SapTransportId.YDER: // Label: Regular return
      return TransportationTypes.EMPTY_SMALL
    case SapTransportId.YSER: // Label: Full truck return
      return TransportationTypes.EMPTY_TRUCK
    default:
      return sapId
  }
}
