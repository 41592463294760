import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d49f0e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "change-line-header" }
const _hoisted_2 = { class: "change-line-description" }
const _hoisted_3 = { class: "accordion-icon" }
const _hoisted_4 = {
  key: 0,
  class: "change-details"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsahiTypography = _resolveComponent("AsahiTypography")!
  const _component_accordion_arrow_icon = _resolveComponent("accordion-arrow-icon")!
  const _component_calendar_home_notification_change_mobile = _resolveComponent("calendar-home-notification-change-mobile")!
  const _component_calendar_home_notification_change = _resolveComponent("calendar-home-notification-change")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'change-line-full-border': !_ctx.isMobile})
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["change-line", {'change-line-border-bottom mobile': _ctx.isMobile}]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expanded = !_ctx.expanded))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AsahiTypography, { type: "subtitle-2-extrabold" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.exception.shipTo), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.shipToDescription), 1)
      ]),
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_accordion_arrow_icon, { expanded: _ctx.expanded }, null, 8, ["expanded"])
      ])
    ], 2),
    (_ctx.expanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_calendar_home_notification_change_mobile, {
                key: 0,
                changes: _ctx.deliveryExceptions
              }, null, 8, ["changes"]))
            : (_openBlock(), _createBlock(_component_calendar_home_notification_change, {
                key: 1,
                changes: _ctx.deliveryExceptions
              }, null, 8, ["changes"]))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}